<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhConsent></MhConsent>

	2020-11-23	init

-->

<template>
	<div class="MhConsent MhConsentModal" :class="elmClasses">
		<div class="MhConsentModal__appOverlay"></div>
		<div class="MhConsentModal__body" ref="modalBody">

			<!-- title -->
			<h3 class="MhConsentModal__title MhConsent__hideWhenEmpty"
				:class="titleClass"
				v-html="_.get(modalData, 'title')"
			></h3>

			<!-- description • simpleView -->
			<p  class="MhConsentModal__text"
				:class="textClass"
				v-if="!isExtendedView"
				v-html="_.get(modalData, 'description--simpleView')"
			></p>
			<!-- description • isExtendedView -->
			<p  class="MhConsentModal__text"
				:class="textClass"
				v-if="isExtendedView"
				v-html="_.get(modalData, 'description--isExtendedView')"
			></p>

			<!-- consentGroups • simpleView -->
			<div class="MhConsent__card MhConsentModal__groups"
				 :class="{ 'MhConsentModal__groups--simpleView' : !isExtendedView }"
				 v-if="!isExtendedView">
				<div class="MhConsentModal__group"
					v-for="(consentGroup, i) in consentGroups"
					:key="i+consentGroup.title">
					<label class="MhConsentModal__groupLabel">
						<input class="MhConsentModal__groupCheckbox"
							   type="checkbox"
							   :disabled="consentGroup.isRequired"
							   :checked="isGroupCheckboxChecked( consentGroup.id )"
							   @change="doChangeGroupCheckbox( consentGroup.id, $event )"
						/>
						<span class="MhConsentModal__groupTitle">{{consentGroup.title}} ({{consentGroup.items.length}})</span>
					</label>
				</div>
			</div>
			<!-- consentGroups • isExtendedView -->
			<div class="MhConsentModal__groups"
				 :class="{ 'MhConsentModal__groups--extendedView' : isExtendedView }"
				 v-if="isExtendedView">
				<pre class="font font--sizeSmall" name="editingState">{{editingState}}</pre>

				<div class="MhConsent__card MhConsentModal__group"
					v-for="(consentGroup, i) in consentGroups"
					:key="i+consentGroup.title">
					<span class="MhConsentModal__groupTitle">{{consentGroup.title}} ({{consentGroup.items.length}})</span>

					<label class="MhConsentModal__groupLabel">
						<input class="MhConsentModal__groupCheckbox"
							   type="checkbox"
							   :disabled="consentGroup.isRequired"
							   :checked="isGroupCheckboxChecked( consentGroup.id )"
							   @change="doChangeGroupCheckbox( consentGroup.id, $event )"
						/>
					</label>

					<p class="MhConsentModal__groupDesc" v-html="consentGroup.desc"></p>

					<a class="MhConsentModal__groupShowItemsBtn" @click="doToggleGroupItems( consentGroup.id )">
						Cookieinformation
						<template v-if="!isGroupFolded( consentGroup.id )">einblenden</template>
						<template v-else>ausblenden</template>
					</a>

					<div class="MhConsentModal__groupItems" v-if="isGroupFolded( consentGroup.id )">
						<div class="MhConsentModal__groupItem" v-for="(item, i) in consentGroup.items" :key="i + consentGroup.title">
							<div class="MhConsentModal__groupPair">
								<div class="MhConsentModal__groupPairKey">Akzeptieren</div>
								<div class="MhConsentModal__groupPairValue">
									<!--
									<pre>{{_.find( editingState.userChoices, { id : item.id} ).hasConsent}}</pre>
									-->
									<label>
										<input
											class="MhConsentModal__groupPairCheckbox"
											type="checkbox"
											:disabled="consentGroup.isRequired"
											:checked="isItemCheckboxChecked( item.id )"
											@change="doChangeItemCheckbox( item.id, $event )"
										/>
									</label>
								</div>
							</div>
							<div class="MhConsentModal__groupPair" v-if="item.title">
								<div class="MhConsentModal__groupPairKey">Name</div>
								<div class="MhConsentModal__groupPairValue" v-html="item.title"></div>
							</div>
							<div class="MhConsentModal__groupPair" v-if="item.provider">
								<div class="MhConsentModal__groupPairKey">Anbieter</div>
								<div class="MhConsentModal__groupPairValue" v-html="item.provider"></div>
							</div>
							<div class="MhConsentModal__groupPair" v-if="item.purpose">
								<div class="MhConsentModal__groupPairKey">Zweck</div>
								<div class="MhConsentModal__groupPairValue" v-html="item.purpose"></div>
							</div>
							<div class="MhConsentModal__groupPair" v-for="(pair, i) in item.properties" :key="i + pair.label">
								<div class="MhConsentModal__groupPairKey"   v-html="pair.label"></div>
								<div class="MhConsentModal__groupPairValue" v-html="pair.text"></div>
							</div>
						</div>

						<!--
						<pre name="items">{{consentGroup.items}}</pre>
						-->
					</div>
				</div>
			</div>

			<div class="MhConsentModal__confirmButtons">
				<button class="MhConsent__button MhConsent__button--cta MhConsentModal__confirmButton" @click.prevent="doSaveChoices( true )">Alle akzeptieren</button>
				<button class="MhConsent__button MhConsentModal__confirmButton" @click.prevent="doSaveChoices()">Speichern</button>
			</div>

			<div class="MhConsentModal__footer">
				<a class="MhConsent__link MhConsentModal__footerLink" @click="isExtendedView = !isExtendedView">
					<template v-if="!isExtendedView">Erweiterte</template>
					<template v-else>Einfache</template>
					Datenschutzeinstellungen
				</a><br />

				<a class="MhConsent__link MhConsent__hideWhenEmpty MhConsentModal__footerLink"
					v-for="(link, i) in _.get(modalData, 'footerLinks', [])"
					:key="i+link.url"
					:href="link.url" v-html="link.label"
				></a>
			</div>

		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import isEqual from 'lodash/isEqual'
	import store from "./store";

	export default {
		name: 'MhConsentModal',
		components: {},
		mixins: [],
		props: {
			titleClass: {
				type: [String, Boolean],
				default: 'titleClass',
			},
			textClass: {
				type: [String, Boolean],
				default: 'textClass',
			},
			buttonClass: {
				type: [String, Boolean],
				default: 'buttonClass',
			},
			disclaimerClass: {
				type: [String, Boolean],
				default: 'disclaimerClass',
			},
		},
		data() {
			return {
				isExtendedView : true,
				unfoldedGroups : [],
				editingState   : {
					userChoices : []
				},
				// array of javascriptCode that was already executed
				// this helps to prevent double code execution
				executedScripts : []
			}
		},
		watch: {
			'isModalVisible': {
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.groupCollapsed( this.$options.name + ' • watch isModalVisible', to)
						console.groupEnd()
					}

					if( to ) this.onShowModal()
				},
				immediate: false,
				deep: false,
			},
			'isReady': {
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.groupCollapsed( this.$options.name + ' • watch isReady', to)
						console.groupEnd()
					}

					if( to ) this.doEvalScripts()
				},
				immediate: false,
				deep: false,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isModalVisible && this.isModalEnabled ){
					classes.push('MhConsentModal--isVisible')
				}else{
					classes.push('MhConsentModal--isHidden')
				}

				return classes
			},

			consentGroups(){
				const data = this.$store.getters.consentGroups

				return data
			},
			consentItems(){
				const data = this.$store.getters.consentItems

				/*
				console.group( this.$options.name + ' • consentItems')
				console.log('consentItems:', consentItems)
				console.groupEnd()
				*/

				return data
			},
			userChoices(){
				const data = this.$store.getters.userChoices

				return data
			},
			modalData(){
				const data = this.$store.getters.modalData

				return data
			},
			isModalVisible(){
				const data = this.$store.getters.isModalVisible

				return data
			},
			isModalEnabled(){
				const data = this.$store.getters.isModalEnabled

				return data
			},
			isReady(){
				const data = this.$store.getters.isReady

				return data
			},
		},
		methods: {
			showModalClickHandler( e, doLog = false ){
				const target = e.target
				const doAction = target.classList.contains("mhConsent__editConsents")

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • showModalClickHandler( e )')
					console.log('e:', e)
					console.log('target:', target)
					console.log('doAction:', doAction)
					console.groupEnd()
				}

				if( doAction ) this.$store.commit('forceModalVisible', true)
			},

			isGroupFolded( groupId ){
				return this.unfoldedGroups.includes( groupId )
			},
			isGroupCheckboxChecked( groupId, doLog = false ){
				const consentGroup    = this.consentGroups.find((o) =>{ return o.id == groupId })
				const groupItems      = consentGroup ? consentGroup.items : null
				const groupItemsIds   = groupItems ? groupItems.map( o=>{ return o.id }) : []
				const isGroupRequired = consentGroup ? consentGroup.isRequired : false
				let   groupConsents   = []
				let   isGroupChecked  = false

				groupItemsIds.forEach( id=>{
					const choiceItem = this._.find( this.editingState.userChoices, { id : id } )
					const hasConsent = choiceItem ? choiceItem.hasConsent : false

					groupConsents.push( hasConsent )
				})

				groupConsents = this._.uniq( groupConsents )
				isGroupChecked = ( groupConsents.length == 1 && groupConsents[0] === true ) ? true : false

				if( doLog ){
					console.group( this.$options.name, '• isGroupCheckboxChecked( groupId )')
					console.log('groupId:', groupId)
					console.log('consentGroup:', consentGroup)
					console.log('groupItemsIds:', groupItemsIds)
					console.log('groupConsents:', groupConsents)
					console.log('isGroupRequired:', isGroupRequired)
					console.log('isGroupChecked:', isGroupChecked)
					console.groupEnd()
				}

				return isGroupChecked //isChecked
			},
			doChangeGroupCheckbox( groupId, e, doLog = false ){
				const isChecked     = e.target.checked
				const consentGroup  = this.consentGroups.find((o) =>{ return o.id == groupId })
				const groupItems    = consentGroup ? consentGroup.items : null
				const groupItemsIds = groupItems ? groupItems.map( o=>{ return o.id }) : []

				// items hasConsent follows group checkbox state
				groupItemsIds.forEach( id=>{
					const choiceItem = this._.find( this.editingState.userChoices, { id : id } )

					choiceItem.hasConsent = isChecked
				})

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• doChangeGroupCheckbox( groupId )')
					console.log('groupId:', groupId)
					console.log('isChecked:', isChecked)
					console.log('consentGroup:', consentGroup)
					console.log('groupItemsIds:', groupItemsIds)
					console.groupEnd()
				}
			},
			doToggleGroupItems( groupId ){
				if( !this.unfoldedGroups.includes( groupId ) ){
					this.unfoldedGroups.push( groupId )
				}
				else{
					this.unfoldedGroups = this._.without( this.unfoldedGroups, groupId)
				}
			},

			isItemCheckboxChecked( itemId ){
				const editingUserChoices = this._.get( this.editingState, 'userChoices' )
				const userChoiceItem     = editingUserChoices ? editingUserChoices.find((o)=>{ return o.id === itemId }) : null
				const isChecked 	     = userChoiceItem ? userChoiceItem.hasConsent : false

				return isChecked
			},
			doChangeItemCheckbox( itemId, e, doLog = false ){
				const isChecked   = e.target.checked
				const editingItem = this._.find( this.editingState.userChoices, { id : itemId} )

				if( isChecked ){
					editingItem.hasConsent = true
				}
				else{
					editingItem.hasConsent = false
				}

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• doChangeItemCheckbox( itemId, e )')
					console.log('itemId:', itemId)
					console.log('e:', e)
					console.log('isChecked:', isChecked)
					console.log('editingItem:', editingItem)
					console.groupEnd()
				}
			},

			onShowModal( doLog = false ){ // set editingState
				const clonedConsentGroups   = this._.cloneDeep( this.consentGroups )
				const clonedUserChoiceItems = this._.cloneDeep( this.userChoices )
				const clonedConsentItems    = this._.cloneDeep( this.consentItems )
				const editingUserChoices    = []

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onShowModal()')
					console.log('clonedConsentGroups:', clonedConsentGroups)
					console.log('clonedUserChoiceItems:', clonedUserChoiceItems)
					console.log('clonedConsentItems:', clonedConsentItems)
					console.log('this.editingState:', this.editingState)
					console.log('this.$refs.modalBody:', this.$refs.modalBody)
					console.log('-----')
				}

				// reset modalBody scroll position
				this.$nextTick(()=>{ this.$refs.modalBody.scroll(0, 0) })

				// fill editingUserChoices
				// walk all consentItems and set hasConsent by userChoices
				clonedConsentItems.forEach( item=>{
					const matchingUserChoiceItem = this._.find( clonedUserChoiceItems, { id : item.id} )
					const choiceItem = {
						id         : item.id,
						hasConsent : matchingUserChoiceItem ? matchingUserChoiceItem.hasConsent : null,
						timestamp  : matchingUserChoiceItem ? matchingUserChoiceItem.timestamp : null,
					}

					if( item.isRequired ) choiceItem.hasConsent = true

					editingUserChoices.push( choiceItem )
				})

				// set editing state
				this.editingState.userChoices = editingUserChoices

				if( doLog ){
					console.log('editingUserChoices:', editingUserChoices)
					console.groupEnd()
				}
			},
			doSaveChoices( acceptAll = false, doLog = false ){
				// on acceptAll: consent to all
				if( acceptAll ){
					this.editingState.userChoices.forEach((choice)=>{
						choice.hasConsent = true
					})
				}

				// prepare items for commit:
				// set timestamps + hasConsent as boolean
				this.editingState.userChoices.forEach((choice)=>{
					choice.hasConsent = choice.hasConsent ? true : false // force boolean, transforms null to false
					choice.timestamp = Math.floor(Date.now() / 1000)
				})

				if( doLog ){
					console.group( this.$options.name, '• doSaveChoices( acceptAll=false )')
					console.log('acceptAll:', acceptAll)
					console.log('this.editingState.userChoices:', this.editingState.userChoices)
					console.groupEnd()
				}

				this.$store.commit('userChoices', this._.cloneDeep( this.editingState.userChoices) )
				this.doHideModal()
				this.doEvalScripts()
			},
			doHideModal(){
				this.unfoldedGroups = []
				//this.editingState.userChoices = []
				this.$store.commit('forceModalVisible', false)
			},

			doEvalScripts( doLog = false ){ // eval script for consent given items
				const userChoices      = this.userChoices.filter(       o=>{ return o.hasConsent })
				const userChoicesIds   = userChoices ? userChoices.map( o=>{ return o.id }) : []
				const userChoicesItems = this.consentItems.filter(      o=>{ return userChoicesIds.includes(o.id) } )
				const requiredItems    = this.consentItems.filter(      o=>{ return o.isRequired === true } )

				// collect user choices and required scripts
				let scripts = this._.concat(
					requiredItems ? requiredItems.map( o=>{ return o.javascriptCode }) : [],
					userChoicesItems ? userChoicesItems.map( o=>{ return o.javascriptCode }) : [],
				)

				scripts = this._.uniq( scripts ) // remove duplicated
				scripts = this._.compact( scripts ) // remove empty

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name + ' • doEvalScripts()')
					console.log('userChoices:', userChoices)
					console.log('userChoicesIds:', userChoicesIds)
					console.log('userChoicesItems:', userChoicesItems)
					console.log('requiredItems:', requiredItems)
					console.log('scripts:', scripts)
					console.groupEnd()
				}

				// run the scripts
				scripts.forEach( script=>{
					if( this.isModalEnabled && !this.executedScripts.includes(script) ){
						this.executedScripts.push( script )
						setTimeout(()=>{
							script = script.replace(/<\/?script>/g,"")
							eval(script)
						}, 0)
					}
				})
			},
		},
		created(){
			// feature: show edit modal on click .mhConsent__editConsents
			const body = document.querySelector('body')
			body.addEventListener('click', this.showModalClickHandler)
		},
		mounted(){},
		beforeDestroy(){
			const body = document.querySelector('body')
			body.removeEventListener('click', this.showModalClickHandler)
		},
		destroyed(){},
	}
</script>

<style lang="less"></style>
