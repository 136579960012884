<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvMainHeaderMobile></OvMainHeaderMobile>

	2020-11-23	init

-->

<template>
	<div class="OvMainHeaderMobile background background--white" :class="elmClasses">
		<div class="OvMainHeaderMobile__inner">

			<div class="OvMainHeaderMobile__header">
				<router-link class="OvMainHeaderMobile__headerLogo font font--bold font--sizeSmall" :to="'/'">
					<div class="">I I I I</div>
					<div class="">Kunst- und Kulturstiftung</div>
					<div class="">Opelvillen Rüsselsheim</div>
				</router-link>
				<div class="OvMainHeaderMobile__headerBtns">
					<template v-if="app.lang == 'de'">
						<a class="OvMainHeaderMobile__headerBtn OvMainHeaderMobile__headerBtn--toggleLang" role="button" @click="app.doChangeLang('en')">
							EN
						</a>
					</template>
					<template v-if="app.lang == 'en'">
						<a class="OvMainHeaderMobile__headerBtn OvMainHeaderMobile__headerBtn--toggleLang" role="button" @click="app.doChangeLang('de')">
							DE
						</a>
					</template>
					<router-link
						@click.native="isMenuOpen = false"
						class="OvMainHeaderMobile__headerBtn OvMainHeaderMobile__headerBtn--searchBtn"
						:to="{ name: 'OvSearchResultsView', params: { lang: app.lang }  }"
					>Search</router-link >
					<a class="OvMainHeaderMobile__headerBtn OvMainHeaderMobile__headerBtn--toggleMenu" role="button" @click="isMenuOpen = !isMenuOpen">
						<span>{{ $t('button.menu' )}}</span>
					</a>

				</div>
			</div>
			<div class="OvMainHeaderMobile__body">
				<OvMainMenu
					class="OvMainHeaderMobile__bodyMainMenu"
					:layout="'mobile'"
					ref="mainMenu"
				></OvMainMenu>
			</div>

		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import OvMainMenu from '@/components/OvMainMenu.vue'

	export default {
		name: 'OvMainHeaderMobile',
		components: {
			OvMainMenu,
		},
		props: {},
		data() {
			return {
				isMenuOpen : false,
			}
		},
		watch: {
			isMenuOpen: { // close all menu items groups to start with an nice clean state the next time
				handler: function( to, from ){
					if(!to){ // if menu is closing: hide all items after menu has closed
						setTimeout(()=>{
							this.$refs.mainMenu.closeAllItemsOnMobile()
						}, 500) // wait before closing animation ends
					}
					this.toggleBodyOverflow() // toggle body overflow property
				},
			},
			'$route.fullPath': {
				handler: function( to, from ){
					this.isMenuOpen = false
				},
			}
			/*
			'mainMenu.isHovered': {
				handler: function( to, from ){
					if( to ){
						this.doExpandMainHeader()
					}else{
						this.doShrinkMainHeader()
					}
				},
				deep: false,
			},
			*/
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isMenuOpen ) classes.push( this.$options.name + '--hasOpenMenu')

				return classes
			},
			infoText(){
				const currentLang = this.app.lang
				const infoText    = this._.get( this.app, 'acfOptions.headerText__' + currentLang, '')

				return infoText
			},
		},
		methods: {
			getLinkWithoutHostname( url ){
				let _url = new URL( url )
				return _url.pathname
			},
			toggleBodyOverflow( doLog = false ){
				const bodyElm = document.querySelector('body')
				const isMenuOpen = this.isMenuOpen

				bodyElm.style.overflow = isMenuOpen ? 'hidden' : ''

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• toggleBodyOverflow()')
					console.log('bodyElm:', bodyElm)
					console.log('isMenuOpen:', isMenuOpen)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	@OvMainHeaderMobile : {
		//expandingTransitionDuration : 250ms;
		headerHeight : 80px;
		hSpace : 5vw;
		iconWidth: 5vw;
	}

	.OvMainHeaderMobile {	// debug
		[showBorders4] &__inner > * {
			outline: 1px solid red;
		}
		[showBorders4] &__inner > * * {
			outline: 1px solid orange;
		}
		[showBorders4] &__right {
			background-color: fade( cyan, 30 );
		}
		[showBorders4] &__left {
			background-color: fade( green, 30 );
		}
	}
	.OvMainHeaderMobile {	// layout
		position: fixed !important; // just to overwrite debug outlining
		position: relative !important; // just to overwrite debug outlining
		top: 0; left: 0;
		width: 100vw;
		width: calc( 100vw - var(--scrollbarWidth));
		height: @OvMainHeaderMobile[headerHeight];
		overflow: hidden;
		display: flex;
		z-index: 100;

		&--hasOpenMenu {
			height: 100vh;
		}

		&__inner {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
		&__header {
			//.relative; .outline();
			height: @OvMainHeaderMobile[headerHeight];
			display: flex;
			justify-content: space-between;
			flex-grow: 0;
			flex-shrink: 0;
		}
		&__body {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			overflow: hidden;

			//& > * { display: none; }
			overflow-y: auto;
		}

		&__headerLogo {
			//.relative; .outline(blue);
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__bodyMainMenu {}
	}
	.OvMainHeaderMobile {	// styling
		transition: height 0.35s ease-out;
		border-bottom: 1px solid var(--color-primary-backgroundLine);

		&__inner {}
		&__header {
			padding-left: @OvMainHeaderMobile[hSpace];
			padding-right: @OvMainHeaderMobile[hSpace];
			border-bottom: 1px solid var(--color-primary-backgroundLine);
		}
		&__headerLogo {
			color: inherit;
		}
		&__headerBtns {
			display: flex;
		}
		&__headerBtn--toggleLang {
			//outline: 1px solid green;

			position: relative;
			padding: 0 0.75em;
			display: flex;
			align-items: center;

			color: inherit;
		}
		&__headerBtn--toggleMenu {
			//outline: 1px solid green;
			position: relative;
			display: flex;
			align-items: center;

			@enlargeClickableSpace : 10px;
			border-left: @enlargeClickableSpace solid fade(white, 0); // enlarge clickable space
			border-right: @enlargeClickableSpace solid fade(white, 0); // enlarge clickable space
			margin-right: -@enlargeClickableSpace;

			span { display: none; } // Text ausblenden damit flex das icon zentrieren kann

			&::after {
				//outline: 1px solid red;
				display: flex;
				width: @OvMainHeaderMobile[iconWidth];
				height: @OvMainHeaderMobile[iconWidth];
				background-image: url(../assets/imgs/icon--hamburger.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				content: "";
			}
		}
		&__headerBtn--searchBtn {
			border-left: 0.5em solid transparent;
			border-right: 0.5em solid transparent;
			width: 2em;
			background-image: url('./../assets/imgs/search-icon.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			overflow: hidden;
			color: transparent;
		}
		&--hasOpenMenu &__headerBtn--toggleMenu::after {
			background-image: url(../assets/imgs/icon--closeCross.svg);
			width: @OvMainHeaderMobile[iconWidth]*0.85;
			margin-left: @OvMainHeaderMobile[iconWidth]*0.15;
		}

		&__body .OvMainMenu__groupTitle {
			padding-left: @OvMainHeaderMobile[hSpace];
			padding-right: @OvMainHeaderMobile[hSpace];
		}
		&__body .OvMainMenu__groupItem {
			padding-left: @OvMainHeaderMobile[hSpace];
			padding-right: @OvMainHeaderMobile[hSpace];
		}
		&__body .OvMainMenu__groupTitle::after {
			right: @OvMainHeaderMobile[hSpace]*0.85;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
