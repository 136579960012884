<template>
	<div class="app color color--primary-baseText background2--color-primary-background">

		<main class="app__body">
			<OvMainHeader v-if="['xl', 'lg', 'dt'].includes( $mq )"></OvMainHeader>
			<OvMainHeaderMobile v-else></OvMainHeaderMobile>

			<MhRouterViewWrapper></MhRouterViewWrapper>
		</main>

		<div class="gridDebug">
			<div class="maxWidth maxWidth--app grid grid--colGap grid--cols-12 hSpace hSpace--app">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>

		<OvSwatches :appColor="_.get( acfOptions, 'appPrimaryColor', 'black' )"></OvSwatches>

		<MhConsent></MhConsent>
		<MhConsentModal
			:titleClass="'font font--bold'"
			:textClass="'font font--bold'"
		></MhConsentModal>

		<MhDelegateLinks
			:notFoundViewName="'Ov404View'"
		></MhDelegateLinks>

		<!--
			MhMailjet wurde deaktiviert, da ein Google-Font von amerikanischen Server nachgeladen wurde.
			Das verstößt gegen die DSGVO.
		<MhMailjet></MhMailjet>
		-->



		<!--
		<iframe
			v-iframeResize="{ log: true }"
			data-w-type="embedded"
			frameborder="0"
			scrolling="yes"
			marginheight="0"
			marginwidth="0"
			src="http://x7g3t.mjt.lu/wgt/x7g3t/sij/form?c=1b7d61c0"
			width="100%"
			XXXstyle="height: 300px;"
		></iframe>

		<br/>
		<br/>
		<br/>
		<br/>
		<a @click="showTableModal = true">open MhModal</a>
		<a class="openMailjetModal">open openMailjetModal</a>
		-->

		<MhMailjet2 :openBtnSelector="'.openMailjetModal'"></MhMailjet2>

		<!--
		<MhModal :show="showTableModal" @close="showTableModal = false">
			<template slot="header">
				Newsletter
			</template>
			<template slot="closeButton">
				<span>×</span>
			</template>
			<template slot="body">
				<iframe
					v-iframeResize="{ log: true }"
					data-w-type="embedded"
					frameborder="0"
					scrolling="yes"
					marginheight="0"
					marginwidth="0"
					src="http://x7g3t.mjt.lu/wgt/x7g3t/sij/form?c=1b7d61c0"
					width="100%"
					style="height: 450px; max-height: 75vh;"
				></iframe>
			</template>
			<template slot="footer">
				footer
			</template>
		</MhModal>
		-->

		<MhDevInfos
			:showOnHosts="[
				'localhost',
				'opelvillen.local',
				'dev.opelvillen.de'
			]"
		></MhDevInfos>
	</div>
</template>

<script>
	// @ is an alias to /src
	const Color = require('color')
	import EventBus from '@/helper/EventBus.js'
	import resize from 'vue-resize-directive'
	//import iframeResize from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/directives/iframeResize/v1/iframeResize.directive.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhConsent from '@/components/MhConsent/MhConsent.vue'
	import MhConsentModal from '@/components/MhConsent/MhConsentModal.vue'

	import MhDevInfos from '@/components/MhDevInfos/MhDevInfos.vue'
	import MhDelegateLinks from '@/components/MhDelegateLinks/v3/MhDelegateLinks.vue'
	import MhRouterViewWrapper from '@/components/MhRouterView/MhRouterViewWrapper.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeaderMobile from '@/components/OvMainHeaderMobile.vue'
	import OvSwatches from '@/components/OvSwatches.vue'

	//import MhMailjet from '@/components/MhMailjet/MhMailjet.vue'
	import MhMailjet2 from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMailjet/v2/MhMailjet2.vue'
	//import MhModal from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhModal/v2/MhModal.vue'

	export default {
		name: 'App',
		components: {
			MhConsent,
			MhConsentModal,
			MhDevInfos,
			MhDelegateLinks,
			MhRouterViewWrapper,
			OvMainHeader,
			OvMainHeaderMobile,
			OvSwatches,

			//MhMailjet,
			MhMailjet2,
			//MhModal,
		},
		directives: {
			resize,
			//iframeResize,
		},
		mixins: [ RestHandler ],
		data(){
			return {
				showTableModal     : null,
				acfOptions         : null,
				runningExhibitions : [],
				futureExhibitions  : [],
				monthNames         : {
					de: [
						'Januar',
						'Februar',
						'März',
						'April',
						'Mai',
						'Juni',
						'Juli',
						'August',
						'September',
						'Oktober',
						'November',
						'Dezember',
					],
					en: [
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'September',
						'October',
						'November',
						'December',
					]
				},
				translatedTerms    : [], // just for dev to find out which terms exists
				searchString       : '',
			}
		},
		computed: {
			runningMainExhibitions(){
				return this._.filter( this.runningExhibitions, (o)=>{ return o.acf.category === 1 } )
			},
			runningOtherExhibitions(){
				return this._.filter( this.runningExhibitions, (o)=>{ return o.acf.category !== 1 } )
			},
			futureMainExhibitions(){
				return this._.filter( this.futureExhibitions, (o)=>{ return o.acf.category === 1 } )
			},
			futureOtherExhibitions(){
				return this._.filter( this.futureExhibitions, (o)=>{ return o.acf.category !== 1 } )
			},
			lang(){
				return this.$i18n.locale ? this.$i18n.locale : 'de'
			},
		},
		methods: {
			getHostname(){	// for hidding the devInfos on some environments
				return window.location.hostname
			},
			fetchAcfOptions( doLog = false ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						const result = response.data.result

						this.acfOptions = result

						if( doLog ){
							console.groupCollapsed('App • fetchAcfOptions() callback')
							console.log('acfOptions:', this.acfOptions)
							console.groupEnd()
						}

						//this.setColorVars()
					},
				})
			},
			fetchExhibitions( doLog = false ){
				// range: now (currently running)
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/exhibitions',
					params : {
						range : 'now',
						todayOnlyCache : this.$root.getCurrentDateAsYYYYMMDD(),
					},
					callback : (response) => {
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('App • fetchExhibitions() callback • now')
							console.log('result:', result)
							console.groupEnd()
						}

						result.forEach((o)=>{
							this.runningExhibitions.push( o )
						})
					},
				})
				// range: future
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/exhibitions',
					params : {
						range : 'future',
						todayOnlyCache : this.$root.getCurrentDateAsYYYYMMDD(),
					},
					callback : (response) => {
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('App • fetchExhibitions() callback • future')
							console.log('result:', result)
							console.groupEnd()
						}

						result.forEach((o)=>{
							this.futureExhibitions.push( o )
						})
					},
				})
			},
			setMeasureVars(){
				const root = document.documentElement
				root.style.height = '101vh'
				const scrollbarWidth = window.innerWidth - root.offsetWidth + 'px'
				root.style.height = ''

				root.style.setProperty('--scrollbarWidth', scrollbarWidth)
				//console.log(scrollbarWidth )
			},
			dateToYYYYMMDD( date, joiner = '' ) {
			    var d = new Date(date),
			        month = '' + (d.getMonth() + 1),
			        day = '' + d.getDate(),
			        year = d.getFullYear();

			    if (month.length < 2)
			        month = '0' + month;
			    if (day.length < 2)
			        day = '0' + day;

			    return [year, month, day].join( joiner )
			},
			replaceAll( str, find, replace ){
				return str ? str.replace( new RegExp(find, 'g'), replace ) : undefined
			},
			nl2br( str, is_xhtml ){
				if (typeof str === 'undefined' || str === null) {
					return '';
				}
				var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
				return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
			},
			doChangeLang( to, doLog = false ){
				const route       = this.$route
				const fullPath    = route.fullPath
				const currentLang = this.$root.$i18n.locale
				let newFullPath   = ''

				this.$root.$i18n.locale = to

				if( to == 'de' ) newFullPath = this.replaceAll( fullPath, '/en/', '/de/' )
				if( to == 'en' ) newFullPath = this.replaceAll( fullPath, '/de/', '/en/' )

				if( doLog ){
					console.groupCollapsed('App • changeLang()')
					console.log('currentLang:', currentLang)
					console.log('to:', to)
					console.log('route:', route)
					console.log('fullPath:', fullPath)
					console.log('newFullPath:', newFullPath)
					console.groupEnd()
				}

				this.$router.push( newFullPath )
			},
			isUpcomingExhibition( post, doLog = false ){
				const todayDate  = parseInt( this.dateToYYYYMMDD( new Date() ) )
				const startDate  = parseInt( this._.get( post, 'acf.startDate', '' ).replace(/-/g, "") )
				const endDate    = parseInt( this._.get( post, 'acf.endDate', '' ).replace(/-/g, "") )
				const isMatching = startDate > todayDate ? true : false

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• isUpcomingExhibition( post )')
					console.log('todayDate:', todayDate)
					console.log('post:', post)
					console.log('startDate:', startDate)
					console.log('endDate:', endDate)
					//console.log('startDate <= todayDate:', startDate <= todayDate)
					//console.log('endDate >= todayDate:', endDate >= todayDate)
					console.log('isMatching:', isMatching)
					console.groupEnd()
				}

				return isMatching
			},
			isRunningExhibition( post, doLog = false ){
				const todayDate  = parseInt( this.dateToYYYYMMDD( new Date() ) )
				const startDate  = parseInt( this._.get( post, 'acf.startDate', '' ).replace(/-/g, "") )
				const endDate    = parseInt( this._.get( post, 'acf.endDate', '' ).replace(/-/g, "") )
				const isMatching = startDate <= todayDate && endDate >= todayDate ? true : false

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• isRunningExhibition( post )')
					console.log('todayDate:', todayDate)
					console.log('post:', post)
					console.log('startDate:', startDate)
					console.log('endDate:', endDate)
					//console.log('startDate <= todayDate:', startDate <= todayDate)
					//console.log('endDate >= todayDate:', endDate >= todayDate)
					console.log('isMatching:', isMatching)
					console.groupEnd()
				}

				return isMatching
			},
			isArchivedExhibition( post, doLog = false ){
				const todayDate  = parseInt( this.dateToYYYYMMDD( new Date() ) )
				const startDate  = parseInt( this._.get( post, 'acf.startDate', '' ).replace(/-/g, "") )
				const endDate    = parseInt( this._.get( post, 'acf.endDate', '' ).replace(/-/g, "") )
				const isMatching = endDate < todayDate ? true : false

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• isArchivedExhibition( post )')
					console.log('todayDate:', todayDate)
					console.log('post:', post)
					console.log('startDate:', startDate)
					console.log('endDate:', endDate)
					//console.log('startDate <= todayDate:', startDate <= todayDate)
					//console.log('endDate >= todayDate:', endDate >= todayDate)
					console.log('isMatching:', isMatching)
					console.groupEnd()
				}

				return isMatching
			},
			getPostLabelText( post, doLog = false ){
				const pageLabel          = this._.get( post , 'acf.pageLabel' )
				let labelText            = (pageLabel === '–') ? undefined : pageLabel

				const exhibitionCategory = this._.get( post , 'acf.category' ) // only used for exhibitions
				let exhibitionLabel      = ''

				switch( exhibitionCategory ){
					case 1 :
						labelText = 'none';
						break;
					case 4 :
						labelText = 'Labor';
						break;
					case 5 :
						labelText = 'Schleuse';
						break;
				}

				if( doLog ){
					console.groupCollapsed('App • getPostLabelText()')
					console.log('post:', post)
					console.log('pageLabel:', pageLabel)
					console.log('exhibitionCategory:', exhibitionCategory)
					console.log('labelText:', labelText)
					console.groupEnd()
				}

				return labelText
			},
			getPostTitle( post, withLineBreaks = true ){ // return translated title
				const currentLang   = this.$i18n.locale
				let pageTitle     = this._.trim( this._.get( post, 'title.rendered') )
				let acfPageTitles = {
					de : this._.trim( this._.get( post, 'acf.pageTitle__de' ) ),
					en : this._.trim( this._.get( post, 'acf.pageTitle__en' ) ),
				}

				if( withLineBreaks ){
					pageTitle = this.nl2br( pageTitle )
					acfPageTitles.de = this.nl2br( acfPageTitles.de )
					acfPageTitles.en = this.nl2br( acfPageTitles.en )
				}

				const acfPageTitle = acfPageTitles[currentLang]

				return acfPageTitle ? acfPageTitle : pageTitle
			},
			getPostDate( post, doLog = false ){

				const currentLang = this.$i18n.locale
				const dateString  = this._.get( post, 'date', '' )

				if( doLog ){
					console.groupCollapsed('App • getPostDate()')
					console.log('currentLang:', currentLang)
					console.log('dateString:', dateString)
					console.groupEnd()
				}

				const parts       = dateString.split('T')
				const dateParts   = parts[0].split('-')
				const year        = dateParts[0]
				const month       = dateParts[1]
				const monthName   = this.monthNames[currentLang][parseInt(month)-1] //.substring(0, 3)
				const day         = dateParts[2]

				//console.log('parts:', parts, dateParts, monthName)

				return day + '. ' + monthName + ' ' + year
			},
			getPostExcerpt( post, doLog = false ){
				const currentLang       = this.$i18n.locale
				const multiLangContent = {
					de : this._.get( post, 'acf.contentModules__de.contentModules' ),
					en : this._.get( post, 'acf.contentModules__en.contentModules' ),
				}

				const content   = multiLangContent[currentLang]
				const firstText = this._.find( content, { acf_fc_layout : 'text' } )
				let excerptStr  = ''

				if( firstText ){
					const temporalDivElement = document.createElement("div")
					temporalDivElement.innerHTML = firstText.text
					excerptStr = temporalDivElement.innerText
					excerptStr = this._.truncate( temporalDivElement.innerText, {
  						'length': 150,
  						//'omission': ' '
					})
				}

				if( doLog ){
					console.log('content:', content)
					console.log('firstText:', firstText)
					console.log('excerptStr:', excerptStr)
					console.log('---')
				}

				return excerptStr
			},
		},
		created(){
			EventBus.$on('changeSearchTerm', (newTerm)=>{
				this.searchString = newTerm

				console.log(this.$options.name + ' • on changeSearchTerm', newTerm)
			})
		},
		mounted(){
			// fetch acfOptions
			this.fetchAcfOptions()

			// calculate scrollbar width and set as css var
			this.setMeasureVars()

			// fetch currently running exhibtions to set the background color
			this.fetchExhibitions()
		},
		destroyed(){
			EventBus.$off('changeSearchTerm')
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/atoms.less";

	html {}
	body {
		.font;
		.font--sans;
		.font--sizeDefault;

		overflow: hidden;
		overflow-y: scroll;

		// show current css breakpoint as label
		&[showBorders1]::before {
			position: fixed;
			left: 0; top: 0;
			padding: 0.2em;

			background-color: fade(yellow, 85);
			font-size: 14px; line-height: 1em;
			z-index: 100;

			@media @mq[dt] { content: "breakpoint: dt";  }
			@media @mq[lg] { content: "breakpoint: lg";  }
			@media @mq[xl] { content: "breakpoint: xl";  }

			@media @mq[md] { content: "breakpoint: md";  }
			@media @mq[sm] { content: "breakpoint: sm";  }
			@media @mq[xs] { content: "breakpoint: xs";  }
		}
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	a { // handle links
		cursor: pointer;
		color: blue;
		text-decoration: none;

		//.MhPagination &,
		&.link,
		.richText & {
			transition: all 0.1s ease;
			color: inherit;
			text-decoration: underline;
			opacity: 0.65;
			word-wrap: break-word;

			&:hover { opacity: 1; }

			color: var(--color-primary-baseLink);
			text-decoration: none;
			opacity: 0.9;
			&:hover { opacity: 1; text-decoration: underline;; }
			/*
			*/
		}
		&.link--noUnderline {
			text-decoration: none;
			&:hover { text-decoration: none; }
		}
	}
	ul, ol { // handle lists
		margin-left: 1.4rem;

		.richText & {}
	}

	.grid--exhibitionTeaserRow {
		.grid;
		.grid--setAreas;
		.grid--colGap;
		//.grid--rowGap;

		margin: 0 auto;

		@media @mq[dt] {
			.grid--cols-12;
			grid-template-areas: "a a a a a a a a b b b b";
		}
		@media @mq[md] {
			.grid--cols-8;
			grid-template-areas: "a a a a a a a a"
								 "b b b b b b b b";
		}
	}
	.grid--homeTextsRow { // TODO kann raus, wenn die neuen OvHomeCard fertig eingebaut sind
		.grid;
		.grid--setAreas;
		.grid--colGap;
		.grid--rowGap;

		margin: 0 auto;

		@media @mq[dt] {
			.grid--cols-12;
			grid-template-areas: "a a a a a a a a b b b b";
		}
		@media @mq[md] {
			.grid--cols-8;
			grid-template-areas: "a a a a a a a a"
								 "b b b b b b b b";
		}
	}
	.grid--homeMiniPosts { // TODO kann raus, wenn die neuen OvHomeCard fertig eingebaut sind
		.grid;
		.grid--colGap;
		.grid--cols-6;

		@media @mq[dt] {
			& > * { grid-column: span 3; }
		}
		@media @mq[md] {
			& > * { grid-column: span 6; }
		}
	}
	.grid--homeCards {
		.grid;
		.grid--colGap;
		.grid--rowGap;
		.grid--cols-12;

		margin: 0 auto;

		@media @mq[dt] { & > * { grid-column: span 4; } }
		@media @mq[md] { & > * { grid-column: span 6; } }
		@media @mq[sm] { & > * { grid-column: span 12; } }
	}
	.gridDebug {
		[showborders1] & { visibility: visible; }

		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		min-height: 100vh;
		z-index: 100;
		pointer-events: none;
		outline: 1px solid cyan;
		visibility: hidden;

		& > * {
			margin: 0 auto;
			min-height: inherit;
		}
		& > * > * {
			background-color: fade( cyan, 30 );
			outline: 1px solid fade( cyan, 40 );
		}
	}
	.contentModule { // debug outlining
		[showBorders2] & {
			outline: 1px solid fade(red, 60);
			//background-color: fade(red, 0);
			//.outline( red );
			.label( attr(class), fade(red, 60), black );
		}

		[showBorders2] &__inner {
			//border: 1px dotted fade(red, 80);
		}
		//overflow: hidden;
	}
	.contentModule { // general settings
		position: relative;

		&__inner {
			//padding: 1em; // dev only

			position: relative;
			margin: 0 auto;
		}
	}

	.font--sizeHuge {			// 64/77
		font-size: 64px;
		line-height: 1.1em;

		@media @mq[xs] {}
		@media @mq[sm] {}
		@media @mq[md] {
			font-size: 28px;
			line-height: 1.15em;
		}
	}
	.font--sizeBig {			// 64/77
		font-size: 42px;
		line-height: 1.1em;

		@media @mq[xs] {}
		@media @mq[sm] {}
		@media @mq[md] {
			font-size: 42px * 0.85;
			font-size: 28px;
			line-height: 1.15em;
		}
	}
	.font--sizeMedium {			// 32/40 • Subheadlines
		font-size: 32px;
		line-height: 1.25em;

		@media @mq[xs] {}
		@media @mq[sm] {}
		@media @mq[md] {
			font-size: 22px;
		}
	}
	.font--sizeDefault {		// 24/32
		font-size: 24px;
		line-height: 1.333em;

		@media @mq[md] {
			font-size: 16px;
			line-height: 1.2em;
		}
		@media @mq[sm] {}
		@media @mq[xs] {}
	}
	.font--sizeSmall {	    	// 16/22
		font-size: 16px;
		line-height: 1.375em;
		line-height: 1.3em;

		@media @mq[xs] {}
		@media @mq[sm] {}
		@media @mq[md] {
			font-size: 12px;
		}
	}
	.font--sizeMini {			// 12/16
		font-size: 12.5px;
		line-height: 1.333em;

		letter-spacing: 0.025em;

		@media @mq[xs] {}
		@media @mq[sm] {}
		@media @mq[md] {
			font-size: 10px;
		}
	}

	.view {
		//transition: all 0.2s ease;
		padding-top: 3em;

		@media @mq[md] {
			padding-top: 0em;
		}
	}
	.background2 {
		&--color-primary-base { background-color: var(--color-primary-base); }
		&--color-primary-baseDarker { background-color: var(--color-primary-baseDarker); }

		&--color-primary-background { background-color: var(--color-primary-background); }
		&--color-primary-backgroundDarker { background-color: var(--color-primary-backgroundDarker); }

		&--color-primary-highlight { background-color: var(--color-primary-highlight); }
		&--color-primary-highlightDarker { background-color: var(--color-primary-highlightDarker); }
	}
	.color--primary-baseText {
		color: var(--color-primary-baseText);
	}
	.lineCrop {
		//position: relative;
		//background-color: fade( yellow, 50 );
		//transform: translateY(-0.24em);
		&::before {
			content: "";
			display: block;
			height: 0; width: 0;
			margin-top: -0.2em;
		}
		&::after {
			//content: "";
			display: block;
			height: 0; width: 0;
			margin-bottom: -0.05em;
		}
	}
	.layout { // handles responsive grid layouts
		.grid;
		.grid--setAreas;
		.grid--colGap;

		@media @mq[dt] {
			.grid--cols-12;
		}
		@media @mq[md] {
			.grid--cols-8;
		}
		@media @mq[sm] {
			.grid--cols-8;
		}

		[showBorders2] & > * { outline: 1px dotted fade(brown, 80); background-color: fade(brown, 20); }

		&--□□□▨▨▨▨▨▨▨□□ { // OvHtmlEmbed
			@media @mq[dt] {
				grid-template-areas: ". . . a a a a a a a . .";
			}
			@media @mq[md] {
				//grid-template-areas: ". . a a a a a a";
				grid-template-areas: ". . . a a a a a a a . .";
			}
			@media @mq[sm] {
				//grid-template-areas: "a a a a";
			}
		}
		&--□□▨▨▨▨▨▨▨▨□□ { // OvText
			@media @mq[dt] {
				.grid--cols-12;
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				.grid--cols-12;
				//grid-template-areas: ". . a a a a a a";
				grid-template-areas: ". . a a a a . .";
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a a a a a a a";
			}
		}
		&--□□▨▨▨▨▨▨▨▨▨▨ { // OvAccordion + OvLogoGrid
			@media @mq[dt] {
				grid-template-areas: ". . a a a a a a a a a a";
			}
			@media @mq[md] {
				grid-template-areas: "a a a a a a a a";
			}
			@media @mq[sm] {}
		}
	}
	.breakWord {
		word-break: break-word;
	}
	.linkArrow {
		//background-color: fade( red, 50 );
		display: inline-flex;
		flex-shrink: 0;
		flex-grow: 0;
		object-fit: contain;
		width: 1em;
		height: 1em;

		path { fill: var(--color-primary-baseLink); }
	}
	.richText {
		&:not(.richText--noHyphens) { hyphens: auto; }
		& > *:not(:last-child) { margin-bottom: 0.5em; }
	}
	.shadow {
		&--default {
			//box-shadow: 0 3px 8px fade( black, 20 );
			&::before {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				content: "";
				box-shadow: 0 2px 8px fade( black, 15 );
				opacity: 0.75;
				border-radius: inherit;
				pointer-events: none;
				//background-color: fade( red, 20 );
			}
			&::after {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				content: "";
				box-shadow: 0 2px 10px var(--color-primary-baseDarker);
				opacity: 0.25;
				border-radius: inherit;
				pointer-events: none;
			}
		}
		&--OvLabel2 {
			/*
			box-shadow: 0 1px 10px -5px var(--color-primary-shadow),
					    0 2px 3px  -3px var(--color-primary-shadow);
			*/
			box-shadow: 0 1px 10px -5px var(--color-primary-shadow),
					    0 1px 5px  -4px var(--color-primary-shadow);
		}
		&--OvLabel2-circleForm {
			box-shadow: 0 1px 11px -4px var(--color-primary-shadow),
						0 1px 5px  -3px var(--color-primary-shadow);
			box-shadow: 0 1px 9px  -4px var(--color-primary-shadow),
						0 1px 4px  -3px var(--color-primary-shadow);
		}
	}
	.hover {
		transition: all 0.1s ease;

		&--listItem {
			&:hover { background-color: var(--color-primary-backgroundDarker); }
			&.OvEventsList__event--isActive { background-color: var(--color-primary-highlight); }
			&.OvEventsList__event--isActive:hover { background-color: var(--color-primary-highlightDarker); }
		}
		&--blogPostTeaser {
			&:hover { background-color: var(--color-primary-40); }
		}
		&--mainExhibtionTeaser { //}:not(.background--primary-90) {
			background-color: var(--color-primary-base);
			&:hover { background-color: var(--color-primary-baseDarker); }
		}
		&--furtherExhibtionTeaser {
			&:hover{ background-color: var(--color-primary-backgroundDarker); }
		}
	}
	.border {
		&--furtherExhibtionTeaser {
			.OvExhibitionTeaser__inner:before {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				border-top: 3px solid currentColor;
				pointer-events: none;
				z-index: 2;
				content: "";
			}
		}
		&--furtherExhibtionTeaser:last-child {
			.OvExhibitionTeaser__inner:before {
				position: absolute;
				bottom: -3px;
				border-bottom: 3px solid currentColor;
			}
		}
	}
	.vueperSlides {
		@bullet : {
			height : 0.5rem;
			vGap   : 1rem;
			hGap   : 0.25rem;
		}

		.vueperSlide {
			position: relative;
		}
		.vueperSlides__bullets {
			//background-color: fade( red, 20 );

			position: absolute;
			left: 0; right: 0; bottom: 0;

			transition: all 0.2s ease;
		}
		.vueperSlides__bullet {
			//background-color: fade( red, 20 );

			padding-top: @bullet[vGap];
			padding-bottom: @bullet[vGap];
			padding-left: @bullet[hGap];
			padding-right: @bullet[hGap];

			transition: all 0.2s ease;
			opacity: 0.25;

			span {
				overflow: hidden;
				display: inline-block;
				height: @bullet[height]; width: @bullet[height];
				background-color: currentColor;
				border-radius: 50%;
			}
		}
		.vueperSlides__bullet--active {
			opacity: 1;
		}
	}
	.app {
		position: relative;
		min-height: 100vh;
		//width: 100vw;
		overflow: hidden;
		overflow-y: auto;
	}
	.app__body {}
	.exhibitionExtendedLabel { // "Ausstellung verlängert" im OvExhibitionTeaser
		display: inline-flex;
		position: relative;

		//background-color: fade( red, 20 ); span { outline: 1px solid red; }

		&:before {
			content: "|\00a0\00a0"; // is &nbsp;
			color: transparent;
		}

		&__text {
			position: absolute;
			top: 0.35em; left: 0.75em;
			line-height: 1.2em;
			//background-color: red;
			span { display: block; }
			//white-space: nowrap;
		}
		.OvMainHeadline &__text {
			top: 0.6em;
			//background-color: red;
		}

		@media @mq[md] {
			.OvExhibitionTeaser &__text {
				top: .25em;
				span:first-child { display: none; } // hide "Ausstellung"
			}
			.OvMainHeadline &__text {
				top: 0.35em;
				line-height: 1.1em;
				//background-color: red;
			}
		}
		@media @mq[sm] {
			.OvExhibitionTeaser &__text {
				top: .15em;
				//line-height: 1em;
				span:first-child { display: block; } // show "Ausstellung" again
			}
			.OvMainHeadline &__text {
				top: 0.35em;
				line-height: 1.1em;
				//background-color: red;
			}
		}

		.OvExhibitionTeaser__layout-aside & {
			&__text {
				top: .25em; left: 0.5em;
				span:first-child { display: none; } // hide "Ausstellung"
			}
		}


	}
	.miniTitleWithLine { // adds line after mini title
		//background-color: fade( cyan, 50 );
		display: flex;
		//padding-bottom: 1em;

		&:after {
			content: "";
			height: 3px;
			margin-left: 0.5em;
			background-color: currentColor;
			transform: translateY(0.65em);
			flex-grow: 1;
		}
	}
	.vSpace--miniTitle { // mini titles above rects
		//background-color: fade( cyan, 75 );
		padding-bottom: 0.6em;
	}
	.vSpace--readMoreFooter { // z.b. unter dem events widget und unter den blog posts auf home
		//background-color: fade( cyan, 75 );
		padding-top: 1rem;
	}
	.vSpace--viewContent { // space nach dem header und vor dem footer
		//background-color: fade( cyan, 75 );
		padding-top: 5em;
		padding-bottom: 4em;

		@media @mq[md] {
			padding-top: 3.25em;

		}
	}
	.OvMainHeader { // hide desktop header below dt
		@media @mq[md] { display: none; }
	}
	.OvMainHeaderMobile { // hide mobile header above md
		@media @mq[dt] { display: none; }
	}
	.OvLabel2--isArrow {
		//outline: 1px solid red;
		//--color-primary-background: red;
		//border: 1px solid fade( black, 30 );
		.OvLabel2__inner { box-shadow: none; }
		//background-color: var(--color-primary-baseLink) !important;
		background-color: transparent !important;
		//display: none !important;

		path { fill: var(--color-primary-baseLink); }
	}


	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
