<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvMainMenu></OvMainMenu>

	2020-11-23	init

-->

<template>
	<nav class="OvMainMenu" :class="elmClasses">
		<div class="OvMainMenu__inner">

			<div class="OvMainMenu__groups font font--sizeSmall" v-if="menuGroups">
				<div class="OvMainMenu__group OvMainMenu__group--menuGroup" v-for="(group, index) in menuGroups" :key="index">
					<template v-if="layout === 'mobile'">
						<div class="OvMainMenu__groupTitle" :class="{'OvMainMenu__groupTitle--hasOpenItems' :  openItemsOnMobile.includes( index ) }" role="button" @click="toggleItemsOnMobile(index)">
							<strong v-html="group.title"></strong>
						</div>
						<div class="OvMainMenu__groupItems" v-show-slide="openItemsOnMobile.includes( index )">
							<a  class="OvMainMenu__groupItem"
								v-for="(item, index2) in group.items"
								:key="index2"
								:href="item.link"
							><span>{{item.text}}</span></a>
						</div>
					</template>
					<template v-else>
						<div class="OvMainMenu__groupTitle">
							<strong class="OvMainMenu__groupTitleText" v-html="group.title"></strong>
						</div>
						<div class="OvMainMenu__groupItems">
							<a  class="OvMainMenu__groupItem"
								v-for="(item, index2) in group.items"
								:key="index2"
								:href="item.link"
								@click="$emit('menuLinkClicked')"
							><span class="OvMainMenu__groupItemText">{{item.text}}</span></a>
						</div>
					</template>
				</div>
				<!-- lang switcher -->
				<div class="OvMainMenu__group OvMainMenu__group--hideOnMobile">
					<strong class="OvMainMenu__groupTitle" role="button" @click="app.doChangeLang('de')" v-if="app.lang == 'en'">DE</strong>
					<strong class="OvMainMenu__groupTitle" role="button" @click="app.doChangeLang('en')" v-if="app.lang == 'de'">EN</strong>
				</div>
				<!-- search icon  -->
				<div class="OvMainMenu__group OvMainMenu__group--hideOnMobile">
					<router-link
						class="OvMainMenu__groupTitle OvMainMenu__groupTitle--searchBtn"
						:to="{ name: 'OvSearchResultsView', params: { lang: app.lang }  }"
					>Search</router-link >
				</div>
			</div>

			<!--
			<pre name="menuGroups">{{menuGroups}}</pre>
			-->
		</div>
	</nav>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvMainMenu',
		components: {},
		mixins: [],
		props: {
			layout: {
				type: [String],
				default: 'desktop',
			},
		},
		data() {
			return {
				openItemsOnMobile : [],
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//if( this.showGroupLinks ) classes.push('OvMainMenu--showGroupLinks')
				classes.push( this.$options.name + '--layout-' + this.layout)

				return classes
			},
			menuGroups(){
				const currentLang        = this.app.lang
				const mainMenuGroups     = this._.get( this.app, 'acfOptions.mainMenuGroups__' + currentLang, [] )
				const menuGroups         = []

				/*
				console.group('OvMainMenu • mainMenuGroups')
				console.log('runningExhibitions:', runningExhibitions)
				console.log('futureExhibitions:', runningExhibitions)
				console.log('activeExhibition:', activeExhibition)
				console.log('futureExhibition:', activeExhibition)
				console.log('mainMenuGroups:', mainMenuGroups)
				*/

				mainMenuGroups.forEach((group)=>{
					//console.log('group:', group)

					const groupItems = !this._.isArray( group.items ) ? [] : group.items
					let items = []
					groupItems.forEach((item)=>{
						const type     = item.type
						const template = item.template
						let link       = undefined

						if( type == 'template' ){
							switch( template ){
								case 'currentExhibition':
									link = '/'+currentLang+'/aktuell/';
									break;
								case 'futureExhibition':
									link = '/'+currentLang+'/vorschau/';
									break;
								case 'pastExhibitions':
									link = '/'+currentLang+'/archiv/';
									break;
								case 'events':
									link = '/'+currentLang+'/veranstaltungen/';
									break;
								case 'blogPosts':
									link = '/'+currentLang+'/blog/';
									break;
							}
						}else{
							link = item.link
							link = link.replace('\/de\/', '•••')
							link = link.replace('\/en\/', '•••')
							link = link.replace('•••', '\/'+this.app.lang+'\/')

							//console.log('link:', link)
						}

						items.push({
							text : item.text,
							link : link,
						})
					})
					menuGroups.push({
						//title : group.title.replace(/\s/g, "&nbsp;"), // prevent line breaks
						title : group.title, // prevent line breaks
						items : items,
					})
				})

				/*
				console.log('menuGroups:', menuGroups)
				console.groupEnd()
				*/

				return menuGroups
			},
		},
		methods: {
			toggleItemsOnMobile( groupIndex ){
				//console.log('groupIndex:', groupIndex)

				if( this.openItemsOnMobile.includes( groupIndex ) ){
					this.openItemsOnMobile = this._.without( this.openItemsOnMobile, groupIndex )
				}else{
					this.openItemsOnMobile.push( groupIndex )
				}
			},
			closeAllItemsOnMobile(){ // used from parent mobile header
				//console.log('closeAllItemsOnMobile');
				this.openItemsOnMobile = []
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	@OvMainMenu : {
		desktopGroupPadding : 0 0.5em;
		desktopGroupMaxWidth : 135px;
		desktopGroupMaxWidth : 150px;

		mobileFontSize : 22px;
		mobileMenuItemHeight : 45px;
	}

	.OvMainMenu {
		//.outline();

		position: relative;
		display: block;
		user-select: none;

		&--layout-desktop &__groups {
			display: flex;
			justify-content: flex-end;
		}
		&--layout-desktop &__group {
			//.outline( blue );

			position: relative;
			padding: @OvMainMenu[desktopGroupPadding];
			flex-shrink: 0;
			max-width: @OvMainMenu[desktopGroupMaxWidth];

			&Title {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: pointer;

				strong { // prevent overflow and multilines
					white-space: nowrap;
					//overflow: hidden;
					//text-overflow: ellipsis;
					//max-width: calc( 100vw - 3.5em );
				}

				&--searchBtn {
					//background-color: fade(red, 50);
					background-image: url('./../assets/imgs/search-icon.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					width: 1em;
					overflow: hidden;
					color: transparent;

					// enlarge icon
					/*
					border-left: 0.5em solid transparent;
					border-right: 0.5em solid transparent;
					margin-left: -0.75em;
					margin-right: -0.5em;
					*/
				}
			}
			&Items {
				//height: auto !important;
				//visibility: visible !important;
				//overflow: inherit !important;

				display: block;
				padding-top: 1.4em;
				//max-width: 20vw;
				//max-height: 300px;
				//opacity: 1;
			}
			&Item {
				//outline: 1px solid red; background-color: fade( red, 20 );
				display: block;
				color: inherit;
				white-space: pre;
				text-overflow: ellipsis;
				overflow: hidden;
				transition: all 0.1s ease;

				&:empty {    display: none; }
				&[href=""] { display: none; }
				&:hover {
					color: var(--color-primary-baseLink);
				}
			}
		}

		&--layout-mobile {
			width: 100%;
		}
		&--layout-mobile &__groups {}
		&--layout-mobile &__group {
			font-size: @OvMainMenu[mobileFontSize];
			line-height: 1em;

			&--hasNoItems {
				display: none;
			}
			&--hideOnMobile {
				display: none;
			}

			&Title {
				position: relative;
				height: @OvMainMenu[mobileMenuItemHeight];
				border-bottom: 1px solid var(--color-primary-backgroundLine);
				cursor: pointer;
				display: flex;
				align-items: center;

				strong { // prevent overflow and multilines
					//background-color: fade( red, 20 );

					display: block; // darf nicht flex sein, da ansonsten die ellipsis nicht funktioniert
					max-width: calc( 100vw - 3em );
					height: 100%;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: @OvMainMenu[mobileMenuItemHeight];
				}

				// toggle icon
				&::after {
					//outline: 1px solid red;
					position: absolute;
					top: calc( 50% - 0.5em );
					height: 1em; width: 1em;

					transition: all 0.2s ease;
					//background-color: fade( red, 20 );
					background-image: url(../assets/imgs/icon--downTriangle.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					opacity: 0.75;
					content: "";
				}
				&--hasOpenItems::after {
					//background-color: fade( green, 20 );
					transform: rotate(180deg);
				}
			}
			&Items {
				position: relative;
				display: block;
				//display: none;

				background-color: var(--color-primary-background);

				&::before, &::after { // soft shadow on top
					position: absolute;
					left: 0; right: 0;
					height: 0.15em;

					background: linear-gradient(180deg, var(--color-primary-shadow), fade(white, 0));
					opacity: 0.1;
					pointer-events: none;
					content: "";
				}
				&::before {
					top: 0;
				}
				&::after {
					bottom: 0;
					transform: rotate( 180deg );
				}
			}
			&Item {
				display: block;
				min-height: @OvMainMenu[mobileMenuItemHeight] * 0.85;
				max-width: 100vw; // prevents breakout for long-long-words
				display: flex;
				align-items: center;

				border-bottom: 1px solid var(--color-primary-backgroundLine);
				color: inherit;
				text-overflow: ellipsis;
				font-size: 0.85em;
				line-height: 1.35em;
				//white-space: pre;
				//text-indent: 0.5em;
				//overflow: hidden;

				&:empty {    display: none; }
				&[href=""] { display: none; }

				&Text { display: inline-block; padding: 0.25em 0.5em; }
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
