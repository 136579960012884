<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhConsent></MhConsent>

	ein click auf .mhConsent__removeConsents entfernt alle consents
	TODO > ein click auf ❌❌❌ anzeigen des modals mit all seinen settings

	2021-02-18	init

-->

<template>
	<div class="MhConsent" :class="elmClasses"></div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import store from "./store";

	export default {
		name: 'MhConsent',
		components: {},
		mixins: [],
		props: {
			/*
			imageObject: {
				type: [Object, Boolean],
				default: false,
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
			*/
		},
		data() {
			return {}
		},
		watch: {
			'app.acfOptions': { // watch for config is loaded
				handler: function( to, from, doLog = false ) {
					const mhConsentConfig = this._.get( to, 'mhConsent', null )

					if( doLog ){
						console.group( this.$options.name + ' • watch ', 'app.acfOptions', to)
						console.log('mhConsentConfig:', mhConsentConfig)
						console.groupEnd()
					}

					if( mhConsentConfig ) this.onAfterConfigsFetch( mhConsentConfig )
				},
				immediate: true,
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},

			consentGroups(){
				const data = this.$store.getters.consentGroups

				/*
				console.group( this.$options.name + ' • consentItems')
				console.log('consentItems:', consentItems)
				console.groupEnd()
				*/

				return data
			},
			consentItems(){
				const data = this.$store.getters.consentItems

				/*
				console.group( this.$options.name + ' • consentItems')
				console.log('consentItems:', consentItems)
				console.groupEnd()
				*/

				return data
			},
			modalData(){
				const data = this.$store.getters.modalData

				return data
			},
			userChoices(){
				const data = this.$store.getters.userChoices

				return data
			},
			isModalVisible(){
				const data = this.$store.getters.isModalVisible

				return data
			},
		},
		methods: {
			hashString( string ){
				var hash = 0, i, chr;
				for (i = 0; i < string.length; i++) {
				    chr   = string.charCodeAt(i);
				    hash  = ((hash << 5) - hash) + chr;
				    hash |= 0; // Convert to 32bit integer
				}
				return Math.abs( hash ) // only positiv numbers
			},
			removeAllConsentsClickHandler( e, doLog = false ){
				const target = e.target
				const doAction = target.classList.contains("mhConsent__removeConsents")

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • removeAllConsentsClickHandler( e )')
					console.log('e:', e)
					console.log('target:', target)
					console.log('doAction:', doAction)
					console.groupEnd()
				}

				if( doAction ){
					this.$store.commit('userChoices', [])
				}
			},
			onAfterConfigsFetch( data, doLog = false ){ // commit data to store
				let consentGroups = this._.has( data, 'consentGroups' ) ? this._.cloneDeep( data.consentGroups ) : null
				let modalData = this._.has( data, 'modal' ) ? this._.cloneDeep( data.modal ) : null

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • onAfterConfigsFetch( data )')
					console.log('data:', data)
					console.log('-----')
					console.log('consentGroups:', consentGroups)
					console.log('modalData:', modalData)
				}

				if( modalData ){     // prepare and set modalData
					this.$store.commit('modalData', modalData)
				}
				if( consentGroups ){ // prepare and set consentGroups
					// walk the groups
					this._.forEach(consentGroups, (group)=>{
						//if( doLog ) console.log('	group', group.title)

						group.id = this._.camelCase( group.title )

						const groupItemsIds = []

						// walk the items
						group.items.forEach((item)=>{
							const itemId = this._.camelCase( item.title + item.provider ) + '-' + this.hashString( item.javascriptCode )

							item.id = itemId
							item.isRequired = group.isRequired
							item.javascriptCode = this._.trim( item.javascriptCode )

							groupItemsIds.push( itemId )
						})

						// set group id
						//group.id = this.hashString( groupItemsIds.join(',') )
					})

					this.$store.commit('consentGroups', consentGroups)
				}

				if( doLog ){
					console.log('-----')
					console.log('this.consentGroups:', this.consentGroups)
					console.log('this.consentItems:', this.consentItems)
					console.log('this.modalData:', this.modalData)
					console.log('this.userChoices:', this.userChoices)
					console.log('this.isModalVisible:', this.isModalVisible)
					console.groupEnd()
				}

				this.$store.commit('isReady', true)
			},
		},
		created(){
			// register vuex module, only once
			const alreadyRegistered = this.$store.state.mhConsent ? true : false
			if( !alreadyRegistered ) this.$store.registerModule("mhConsent", store)

			// feature: remove consents on click on .mhConsent__editConsents
			const body = document.querySelector('body')
			body.addEventListener('click', this.removeAllConsentsClickHandler)
		},
		mounted(){},
		beforeDestroy(){
			const body = document.querySelector('body')
			body.removeEventListener('click', this.removeAllConsentsClickHandler)
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../../less/vars.less";
	@import (reference) "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	//.MhConsent { display: none !important; }

	.MhConsent { // general vars
		--bigFontSize       : 0.85em;
		--bigLineHeight     : 1em;

		--defaultFontSize   : 0.85em;
		--defaultLineHeight : 1.2em;

		--smallFontSize     : 0.65em;
		--smallLineHeight   : 1.3em;
	}
	.MhConsent { // reusable elements
		&__button {
			appearance: none;
			outline: none;
			padding: 0.5em 0.75em;

			transition: all 0.1s ease-out;
			font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-size: var(--defaultFontSize);
			line-height: var(--defaultLineHeight);
			color: fade(white, 95);
			font-weight: normal;

			border: 1px solid transparent;
			background-color: fade( black, 40);
			letter-spacing: 0.02em;

			&--cta {
				background-color: fade( black, 80);
			}

			&:active, &:hover {
				outline: none;
				border: 1px solid fade( black, 95);
				background-color: fade( black, 85);
				color: fade(white, 95);
			}
		}
		&__link {
			color: inherit;
			text-decoration: underline;
			opacity: 1;
			transition: all 0.1s ease;

			&:hover { opacity: 1; }
		}
		&__card {
			padding: 0.5em;
			border : 1px solid rgba(0, 0, 0, 0.1);
			background-color : rgba(0, 0, 0, 0.05);
		}
		&__hideWhenEmpty {
			&:empty { display: none; }
		}
	}

	.MhConsentModal { // vars
		--padding           : 1em;
		--color             : currentColor;
		--border            : 1px solid rgba(0, 0, 0, 0.1);
		--overlayBackground : rgba(0, 0, 0, 0.35);
		--maxWidth          : 650px;
	}
	.MhConsentModal {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 1em;
		z-index: 1000;

		&--isVisible { display: flex; }
		&--isHidden { display: none; }

		&__appOverlay {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0em;
			background: var(--overlayBackground);
		}

		&__body {
			position: relative;
			padding: var(--padding);
			max-width: var(--maxWidth);
			max-height: 875px;
			overflow: hidden;
			overflow-y: auto;
			pointer-events: auto;

			border: var(--border);
			background-color: white;
		}

		&__title {
			margin-bottom: 1em;

			font-size: var(--bigFontSize);
			line-height: var(--bigLineHeight);
		}

		&__text {
			margin-bottom: 1em;

			font-size: var(--defaultFontSize);
			line-height: var(--defaultLineHeight);
		}

		&__groups {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 1em;
		}
		&__group {
			label {
				display: block;
				display: flex;
				align-items: center;
				cursor: pointer;
			}
			input {
				display: inline-block;
				margin-right: 0.5em;
			}
			span {
				white-space: pre;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&Title {
				font-size: var(--defaultFontSize); line-height: var(--defaultLineHeight);
			}
		}
		&__groups--simpleView &__group {
			width: 50%;
		}
		&__groups--extendedView &__group {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0.25em;

			&Title { width: 80%; margin-bottom: 0.5em; }
			&Label { width: 20%; display: flex; justify-content: flex-end; }
			&Desc  { display: block; width: 100%; margin-bottom: 0.5em; font-size: var(--smallFontSize); line-height: var(--smallLineHeight); }
			&ShowItemsBtn { display: block; width: 100%; font-size: var(--smallFontSize); line-height: var(--smallLineHeight); padding: 0.5em 0; }

			&Items { display: block; width: 100%; padding-top: 0.5em; }
			&Item  { margin-bottom: 1em; font-size: var(--smallFontSize); line-height: var(--smallLineHeight); }

			&Pair { background-color: white; margin-bottom: 2px; display: flex; padding: 0.25em; }
			&PairKey { width: 8em; flex-shrink: 0; }
			&PairValue { flex-grow: 1; }
		}

		&__confirmButtons {
			display: flex;
			flex-direction: column;
			margin-bottom: 0.5em;

			& > * + * { margin-top: 0.25em; }
		}
		&__confirmButton {
			text-align: center;
		}

		&__footer {
			text-align: center;

			&Button {

			}
			&Link {
				display: inline-block;
				margin: 0 0.25em;

				font-size: var(--smallFontSize);
				line-height: var(--smallLineHeight);
			}
		}
	}

	.MhConsentEmbedWrapper { // vars
		--padding           : 0.75em;
		--color             : currentColor;
		--border            : 1px solid rgba(0, 0, 0, 0.1);
		--backgroundColor   : rgba(0, 0, 0, 0.05);
	}
	.MhConsentEmbedWrapper {
		position: relative;
		//margin-bottom: calc( var(--smallLineHeight) * 1 );

		&--isNotAllowed {}

		&__overlay {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			overflow-y: auto;
			display: flex;
			flex-direction: column;

			opacity: 1;
		}
		&--isAllowed &__overlay {
			opacity: 0;
			pointer-events: none;
		}
	}
	.MhConsentEmbedWrapper__overlay {
		&Title {
			margin: 0 0 .75em 0;

			font-size: var(--bigFontSize);
			line-height: var(--bigLineHeight);
		}
		&Text {
			font-size: var(--defaultFontSize);
			line-height: var(--defaultLineHeight);
		}
		&ButtonWrapper {
			margin: 0.5em 0;
		}
		&Button {}
		&RemberChoice {
			margin: 0.5em 0;
			display: flex;
			cursor: pointer;

			font-size: var(--smallFontSize);
			line-height: var(--smallLineHeight);

			label { display: flex; align-items: center; }
			input { margin-right: 0.5em; XXXtransform: translateY(18%);}
			span { opacity: 0.5; transition: all 0.1s ease; }
			&:hover span { opacity: 1; }
		}
		&Expander {
			flex-grow: 1;
		}
		&Disclaimer {
			opacity: 0.5;
			font-size: var(--smallFontSize);
			line-height: var(--smallLineHeight);

			a { color: inherit; text-decoration: underline; opacity: 1; transition: all 0.1s ease; }
			a:hover { opacity: 1; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
