// https://github.com/AlexandreBonaventure/vue-mq
//
// der wert der breakpoints heißt: "dieser bp geht bis"

export default {
	defaultBreakpoint: 'md',
	breakpoints: {
		xs: 350,
		sm: 750,
		md: 1000,
		dt: 1440,
		lg: 1920,
		xl: Infinity,
	},
}