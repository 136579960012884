import Vue from 'vue'
import Router from 'vue-router'
//import HomeView from './views/HomeView.vue'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{	path: '/',
			redirect: '/de/', // set root route language
		},
		{
			path: "/:lang/dev/",
			name: 'OvDevView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "OvDevView" */ '@/views/OvDevView.vue')
		},
		{	path: '/:lang/',
			name: 'OvHomeView',
			//component: HomeView,
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "OvHomeView" */ '@/views/OvHomeView.vue')
		},
		{	path: "/:lang/aktuell/",
			name: 'OvCurrentExhibitionsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvCurrentExhibitionsView" */ '@/views/OvCurrentExhibitionsView.vue')
		},
		{	path: "/:lang/vorschau/",
			name: 'OvFutureExhibitionsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvFutureExhibitionsView" */ '@/views/OvFutureExhibitionsView.vue')
		},
		{	path: "/:lang/archiv/",
			name: 'OvPastExhibitionsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvPastExhibitionsView" */ '@/views/OvPastExhibitionsView.vue')
		},
		{	path: "/:lang/blog/",
			name: 'OvBlogPostsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvBlogPostsView" */ '@/views/OvBlogPostsView.vue')
		},
		{	path: "/:lang/blog/:slug/",
			name: 'OvBlogPostView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvBlogPostView" */ '@/views/OvBlogPostView.vue')
		},
		{	path: "/:lang/veranstaltungen/",
			name: 'OvEventsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvEventsView" */ '@/views/OvEventsView.vue')
		},
		{	path: "/:lang/veranstaltungen/:slug/",
			name: 'OvEventView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvEventView" */ '@/views/OvEventView.vue')
		},
		{	path: "/:lang/ausstellungen/:slug/",
			name: 'OvExhibitionView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvExhibitionView" */ '@/views/OvExhibitionView.vue')
		},
		{	path: "/:lang/search/",
			name: 'OvSearchResultsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvSearchResultsView" */ '@/views/OvSearchResultsView.vue')
		},
		{	path: "/:lang/:slug/",
			name: 'OvPageView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "OvPageView" */ '@/views/OvPageView.vue')
		},
		{	path: "/:lang/*",
			name: 'Ov404View',
			component: () =>
				import( /* webpackChunkName: "Ov404View" */ '@/views/Ov404View.vue')
		},
		{	path: "*",
			name: 'Ov404View2',
			component: () =>
				import( /* webpackChunkName: "Ov404View" */ '@/views/Ov404View.vue')
		},
	]
})
