import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

/*
	@doc user storage (cookie or localStorage)
	
		mhConsent : {		
			userChoices : [
				{ id : facebook8973429, hasConsent : true, timestamp: 1643344556 },
				{ id : instagram4298973, hasConsent : false, timestamp: 1643344558 },
				{ id : linkedIn6798939, hasConsent : true, timestamp: 1643344565 },
			]
		}
	
	@changelog: 
		2021-02-22  init

*/

export default {
	state: {
		consentGroups     : [], // all consent groups with their items from the backend, e.g.[ {...}, {...} ]
		modalData         : {}, // holds all the strings for the modal
		forceModalVisible : false,
		userChoices       : [],
		isReady           : false, // after config is fetched and store is filled
	},
	getters: {
		consentItems : (state, getters) => {
			const consentItems = []
			state.consentGroups.forEach((group)=>{
				group.items.forEach((item)=>{
					consentItems.push(item)
				})
			})
			return consentItems
		},
		consentGroups : (state, getters) => {
			return state.consentGroups
		},
		modalData : (state, getters) => {
			return state.modalData
		},
		userChoices : (state, getters) => {
			const userChoices = localStorage.getItem('mhConsent') ? 
							    JSON.parse(localStorage.getItem('mhConsent')).userChoices : 
								[]
			
			state.userChoices = userChoices
			
			//console.log('state.userChoices:', state.userChoices)
			//console.log('state.userChoices.map():', state.userChoices.map(o=>{return o.hasConsent}))
			
			return state.userChoices
		},
		isModalVisible : (state, getters) => {
			let doLog             = false
			const consentItemsIds = sortBy( getters.consentItems.map((o)=>{ return o.id }) )
			const userChoicesIds  = sortBy( getters.userChoices.map((o)=>{ return o.id }) )
			const idsAreEqual     = isEqual( consentItemsIds, userChoicesIds )
			let isModalVisible
			
			if( doLog ) console.group('Store • isModalVisible')
			
			if( consentItemsIds.length ){
				if( doLog ) console.log('> consentItemsIds.length', consentItemsIds.length)
				if( idsAreEqual ){
					if( doLog ) console.log('> idsAreEqual:', idsAreEqual)
					isModalVisible = false
					if( doLog ) console.log('> isModalVisible:', isModalVisible)
				}else{
					if( doLog ) console.log('> idsAreEqual:', idsAreEqual)
					if( doLog ) console.log('  consentItemsIds:', consentItemsIds)
					if( doLog ) console.log('  userChoicesIds:', userChoicesIds)
					isModalVisible = true					
					if( doLog ) console.log('> isModalVisible:', isModalVisible)
				}
			}
			else{
				if( doLog ) console.log('> consentItemsIds.length', consentItemsIds.length)
				isModalVisible = false
				if( doLog ) console.log('> isModalVisible:', isModalVisible)
			}
			if( state.forceModalVisible ){
				if( doLog ) console.log('> state.forceModalVisible:', state.forceModalVisible)
				isModalVisible = true
				if( doLog ) console.log('> isModalVisible:', isModalVisible)
			} 
			
			if( doLog ) console.groupEnd()
			
			return isModalVisible
		},
		isModalEnabled : (state, getters) => {
			return state.modalData.isEnabled
		},
		isReady : (state, getters) => {
			return state.isReady
		},
	},
	mutations: {
		modalData( state, pairs, doLog = true ){
			state.modalData = pairs
		},
		consentGroups( state, groups, doLog = true ){
			state.consentGroups = groups
		},
		userChoices( state, choices, doLog = true ){
			const value = { userChoices : choices }
			
			state.userChoices = choices
			
			localStorage.setItem('mhConsent', JSON.stringify(value))
		},
		forceModalVisible( state, bool, doLog = true ){
			state.forceModalVisible = bool
			//console.log('state:', state)
		},
		isReady( state, bool, doLog = false ){
			state.isReady = bool
		},
		
		/*
		mhConsent__removeConsentItem( state, item, doLog = true ){},
		mhConsent__emptyConsentItem( state, doLog = true ){},
		
		mhConsent__addUserChoice( state, itemId, doLog = true ){},
		mhConsent__removeUserChoice( state, itemId, doLog = true ){},
		mhConsent__emptyUserChoice( state, doLog = true ){},
		*/
		/*
		addResponse( state, responseObj ) {
			const resultData = responseObj.response.data.result
			
			// if response has posts, 
			// add them to posts []
			// TODO:2250 add every post only once (filter by id)
			if( Array.isArray( resultData ) ){
				resultData.forEach( post => {
					if( post && post.id && post.slug && post.type ){
						state.posts.push( post )
					}
				})
			}
			
			// add response to responses []
			state.responses.push( responseObj );
		},
		addPendingRequest( state, requestId ){
		
			//console.log('state • addPendingRequest( requestId )', requestId)
			
			state.pendingRequests.push( requestId )
			
		},
		removePendingRequest( state, requestId ){
		
			//console.log('state • removePendingRequest( requestId )', requestId)
			
			var filtered = state.pendingRequests.filter(function(value, index, arr){ 
        		return value !== requestId;
    		});
			
			state.pendingRequests = filtered
			
			//console.log('after pendingRequests:', state.pendingRequests)
			//console.log('')			
		},
		*/
	}
}
