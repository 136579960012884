<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvSwatches></OvSwatches>

	2020-11-23	init

-->

<template>
	<div class="OvSwatches" :class="elmClasses">
		<div class="OvSwatches__inner">
			OvSwatches<br/>
			<hr/>
			<table class="OvSwatches__table">
				<tr class="OvSwatches__tableRow" v-for="(palette, i) in palettes" :key="i">
					<td class="OvSwatches__tableCell" v-for="(swatchValue, swatchName) in palette" :key="swatchName" @click="applyPalette( palette )">
						<span class="OvSwatches__colorSpot" :style="getSwatchStyles( swatchValue )"></span>
						<span class="OvSwatches__swatchInfo">{{getLuminosity(swatchValue)}}</span>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
	const Color = require('color')

	export default {
		name: 'OvSwatches',
		components: {},
		mixins: [],
		props: {
			appColor: {
				type: [String, Boolean],
				default: false,
			},
		},
		data() {
			return {
				palettes     : [],
				sampleColors : [
					/*
					'white',   //
					'black',   //
					'grey',    //
					'#fae697', // sanftes rosa
					'#fdb013', // sanftes rosa
					'#ffc7a8', // postgelb
					'#e51c28', // klares rot • Liebesgrüße aus Havanna
					'#ccccfd', // milka
					'#a0bd3f', // grün
					'#3d5465', // sybille
					'#272525', // tamara
					*/
				],
			}
		},
		watch: {
			appColor: {
				handler: function( to, from ) {
					this.sampleColors.unshift( to )
					//console.log('appColor to:', to)
				},
				deep: true,
			},
			sampleColors: {
				handler: function( to, from ) {
					this.createPalettes()
					this.applyPalette( this.palettes[0] )
				},
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			getLuminosity( colorString, doLog = false ){
				const color = Color( colorString )
				const luminosity = Math.round( color.luminosity() * 100) / 100
				const contrast   = color.contrast( Color("black") )

				if( doLog ){
					console.group('OvSwatches • getLuminosity( colorString )', colorString)
					console.log('colorString:', colorString)
					console.log('luminosity:', luminosity)
					console.log('contrast:', contrast)
					console.groupEnd()
				}
				/*
				*/

				return luminosity
			},
			getSwatchStyles( colorStr ){
				let styles = {}

				styles.backgroundColor = colorStr

				return styles
			},
			createColorByLuminosity( colorString, targetLuminosity = 0.5, doLog = false ){
				const inputColor = new Color( colorString )
				const inputColorLuminosity = inputColor.luminosity()
				const direction = (targetLuminosity > inputColorLuminosity) ? 'lighten' : 'darken'
				let targetColor = new Color( inputColor.hsl() )

				//	groupCollapsed
				//	group
				if( doLog ){
					console.group('OvSwatches • createColorByLuminosity( colorString, contrast )')
					console.log('colorString:', colorString)
					console.log('inputColorLuminosity:', inputColorLuminosity)
					console.log('targetLuminosity:', targetLuminosity)
					console.log('------')
					console.log('direction:', direction)
					console.log('------')
				}

				// perform the darken/lighten manipulation
				// stop when target is reached
				// and set new targetColor
				let countRuns = 0
				for( let i = 0; i < 101; i++ ){
					let factor
					if( direction == 'lighten' ) factor = i
					if( direction == 'darken' ) factor = 100 - i

					let newTargetColor = new Color( targetColor.hsl() )
					newTargetColor = newTargetColor.lightness(factor)

					const newTargetColorLuminosity = newTargetColor.luminosity() // Math.round( newTargetColor.luminosity() * 100) / 100
					if( doLog ) console.log('factor:', factor, ' > ', newTargetColorLuminosity)

					if( direction == 'lighten' && newTargetColorLuminosity >= targetLuminosity ){
						targetColor = newTargetColor
						i = 1000 // stop loop
					}
					if( direction == 'darken' && newTargetColorLuminosity <= targetLuminosity ){
						targetColor = newTargetColor
						i = 1000 // stop loop
					}

					countRuns++
				}

				if( doLog ){
					console.log('------')
					console.log('countRuns:', countRuns)
					console.log('targetColor:', targetColor)
					console.log('targetColorLuminosity:', Math.round( targetColor.luminosity() * 100) / 100)
					console.groupEnd()
				}

				return targetColor
			},
			applyPalette( palette, doLog = false ){
				const root = document.documentElement
				//root.style.setProperty('--color-primary',    colorPrimary.hsl())

				//	groupCollapsed
				//	group
				if( doLog ){
					console.group('OvSwatches • applyPalette( palette )')
					console.log('palette:', palette)
					console.log('------')
				}

				this._.forEach( palette, (colorValue, colorName)=>{
					if( doLog ) console.log(colorName, colorValue)
					root.style.setProperty('--color-primary-' + colorName, colorValue)
				})

				if( doLog ){
					console.groupEnd()
				}
			},
			createPalettes(){
				this.palettes = []
				const baseMaxLight = 0.20
				const baseMaxDark = 0.75

				this.sampleColors.forEach( (baseColor)=>{
					const baseColorLuminosity = this.getLuminosity( Color( baseColor ).rgb() )

					// max darkness
					if( baseColorLuminosity < baseMaxLight ) baseColor = this.createColorByLuminosity( baseColor, baseMaxLight ).rgb()
					// max lightness
					if( baseColorLuminosity > 0.65 ) baseColor = this.createColorByLuminosity( baseColor, 0.65 ).rgb()
					if( baseColorLuminosity > baseMaxDark ) baseColor = this.createColorByLuminosity( baseColor, baseMaxDark ).rgb()

					let palette = {
						base             : baseColor,
						baseDarker       : Color( baseColor ).darken(0.05).rgb() + '',

						//background       : this.createColorByLuminosity( baseColor, 0.90 ).rgb() + '',
						background       : this.createColorByLuminosity( baseColor, 0.93 ).rgb() + '',
						backgroundDarker : this.createColorByLuminosity( baseColor, 0.75 ).rgb() + '',
						//backgroundLine   : this.createColorByLuminosity( baseColor, 0.65 ).desaturate(0.5).rgb() + '',
						backgroundLine   : this.createColorByLuminosity( baseColor, baseMaxDark ).desaturate(0.5).rgb() + '',

						highlight        : baseColor + '',
						highlightDarker  : Color( baseColor ).darken(0.08).rgb() + '',

						shadow           : this.createColorByLuminosity( baseColor, 0.1 ).desaturate(0.5).rgb() + '',
						shadowDarker     : this.createColorByLuminosity( baseColor, 0.1 ).desaturate(0).rgb() + '',

						baseText         : this.createColorByLuminosity( baseColor, 0 ).desaturate(0.5).rgb() + '',
						//baseLink         : this.createColorByLuminosity( baseColor, 0.35 ).rgb() + '',
						//baseLink         : Color( baseColor ).darken(0.15).rgb() + '',
						baseLink         : this.createColorByLuminosity( baseColor, 0.4 ).rgb() + '',
						baseLinkDarker   : this.createColorByLuminosity( baseColor, 0.325 ).rgb() + '',
					}

					this.palettes.push( palette )
				})
			},
		},
		created(){},
		mounted(){
			if( this.appColor ) this.sampleColors.unshift( this.appColor )
			this.createPalettes()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvSwatches {
		display: none !important;

		position: fixed;
		bottom: 0; left: 0;
		z-index: 100;
		padding: 1em;

		font-size: 12px;
		line-height: 1.1em;
		background-color: white;

		&__table {
			border-collapse: collapse;
		}
		&__tableRow {
			border-bottom: 1px solid fade( black, 20 );
			cursor: pointer;
			&:hover { background-color: fade(black, 5); }
		}
		&__tableCell {
			padding: 0.25em 0.35em;
			&:first-child { padding-right: 1.5em; }
		}

		&__colorSpot {
			display: inline-block;
			vertical-align: middle;
			height: 1.5em; width: 1.5em;

			border-radius: 50%;
			border: 1px solid fade( black, 35 );
		}
		&__swatchInfo {
			display: inline-block;
			padding-left: 0.25em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
