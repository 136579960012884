export default {
	de : {
		title : {
			search: 'Suche',
			blog: 'Blog',
			currentExhibition: 'Aktuelle Ausstellung',
			currentExhibitions: 'Aktuelle <br/>Ausstellungen',
			currentAndUpcomingExhibitions: 'Aktuelle und kommende Ausstellungen',
			exhibition: 'Ausstellung',
			exhibitions: 'Ausstellungen',
			resultsFor: 'Ergebnisse für',
			page: 'Seite',
			pages: 'Seiten',
			event: 'Veranstaltung',
			events: 'Veranstaltungen',
			calendar: 'Veranstaltungs- <br/>kalender',
			upcomingExhibitions: 'Kommende <br/>Ausstellungen',
			otherCurrentExhibitions : 'Weitere aktuelle Ausstellungen',
			pastExhibitions : 'Vergangene <br/>Ausstellungen',
			news : 'Blog',
		},
		button : {
			moreBlogPosts : 'Weitere Artikel aus dem Blog',
			moreEvents : 'Alle Veranstaltungen',
			gotoBlog : 'Zum Blog',
			menu : 'Menü',
			newsletterSubscription : 'Newsletter abonnieren',
		},
		breadcrumb : {
			home : 'Startseite',
			blog : 'Blog',
			events : 'Veranstaltungen',
			archivedExhibitions : 'Vergangene Ausstellungen',
			currentExhibitions : 'Aktuelle Ausstellungen',
			upcomingExhibitions : 'Kommende Ausstellungen',
		},
		text : {
			source : 'Quelle',
			author : 'Autor',
			postedAt : 'Gepostet am',
			noCurrentExhibitions : 'Derzeit keine Ausstellungen.',
			noUpcomingExhibitions : 'Derzeit keine kommenden Ausstellungen.',
			extendedExhibition : '<span>wurde</span> <span>verlängert</span>',
			noSearchResults : 'nichts gefunden',
		},
	},
	en : {
		title : {
			search: 'Search',
			blog: 'Blog',
			currentExhibition: 'Current exhibition',
			currentExhibitions: 'Current <br/>exhibitions',
			currentAndUpcomingExhibitions: 'Current and upcoming exhibitions',
			exhibition: 'Exhibition',
			exhibitions: 'Exhibitions',
			resultsFor: 'Results for',
			page: 'Page',
			pages: 'Pages',
			event: 'Event',
			events: 'Events',
			calendar: 'Calendar of <br/>Events',
			upcomingExhibitions: 'Upcoming exhibitions',
			otherCurrentExhibitions : 'Other current exhibitions',
			pastExhibitions : 'Past exhibitions',
			news: 'Blog',
		},
		button : {
			moreBlogPosts : 'Other articles from the blog',
			moreEvents : 'All events',
			gotoBlog : 'Blog',
			menu : 'Menü',
			newsletterSubscription : 'Subscribe to Newsletter'
		},
		breadcrumb : {
			home : 'Home',
			blog : 'Blog',
			events : 'Events',
			archivedExhibitions : 'Past exhibitions',
			currentExhibitions : 'Current exhibitions',
			upcomingExhibitions : 'Upcoming exhibitions',
		},
		text : {
			source : 'Source',
			author : 'Author',
			postedAt : 'Posted',
			noCurrentExhibitions : 'No exhibitions at the moment.',
			noUpcomingExhibitions : 'No upcoming exhibitions at the moment.',
			extendedExhibition : '<span>was</span> <span>extended</span>',
			noSearchResults : 'nothing was found',
		}
	},
}
