<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvMainHeader></OvMainHeader>

	2020-11-23	init

-->

<template>
	<div class="OvMainHeader contentModule background background--white" :class="elmClasses">
		<div class="OvMainHeader__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="onClick" ref="innerElm">

			<div class="OvMainHeader__left" @transitionend="onTransitionEnd">
				<router-link class="OvMainHeader__logo font font--bold font--sizeSmall" :to="'/'">
					<div class="OvMainHeader__logoRow">I I I I</div>
					<div class="OvMainHeader__logoRow">Kunst- und Kulturstiftung</div>
					<div class="OvMainHeader__logoRow">Opelvillen Rüsselsheim</div>
				</router-link>

				<div class="OvMainHeader__metaInfos OvMainHeader__hiddenChild font font--sizeMini">
					<div class="OvMainHeader__metaInfosText richText color color--grey" v-html="infoText"></div>
					<div class="OvMainHeader__metaInfosSocialLinks " v-if="_.get( app, 'acfOptions.socialMedia')">
						<a class="OvMainHeader__metaInfosSocialLink OvMainHeader__metaInfosSocialLink--in" :href="_.get( app, 'acfOptions.socialMedia.instagram')" target="_blank">Instagram</a>
						<a class="OvMainHeader__metaInfosSocialLink OvMainHeader__metaInfosSocialLink--tw" :href="_.get( app, 'acfOptions.socialMedia.twitter')" target="_blank">Twitter</a>
						<a class="OvMainHeader__metaInfosSocialLink OvMainHeader__metaInfosSocialLink--yt" :href="_.get( app, 'acfOptions.socialMedia.youtube')" target="_blank">YouTube</a>
						<a class="OvMainHeader__metaInfosSocialLink OvMainHeader__metaInfosSocialLink--fb" :href="_.get( app, 'acfOptions.socialMedia.facebook')" target="_blank">Facebook</a>
					</div>
				</div>
			</div>
			<div class="OvMainHeader__right">
				<OvMainMenu
					class="OvMainHeader__mainMenu"
				></OvMainMenu>
			</div>

		</div>

		<div class="OvMainHeader__debug" v-if="debug">
			<div class="OvMainHeader__debugHeader">
				<button @click="doCheckDims()">doCheckDims</button>
			</div>
			<div class="OvMainHeader__debugBody">
				<pre>isDisabled: {{isDisabled}}</pre>
				<pre>isEnabled: {{isEnabled}}</pre>

				<pre>isShrinked: {{isShrinked}}</pre>
				<pre>isShrinking: {{isShrinking}}</pre>
				<pre>isExpanded: {{isExpanded}}</pre>
				<pre>isExpanding: {{isExpanding}}</pre>

				<pre>dimensions: {{dimensions}}</pre>
				<!--
				<pre>leftColumn.expandTimeouts: {{leftColumn.expandTimeouts.length}}</pre>
				<pre>leftColumn.shrinkTimeouts: {{leftColumn.shrinkTimeouts.length}}</pre>
				-->
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import EventBus from '@/helper/EventBus.js'
	import OvMainMenu from '@/components/OvMainMenu.vue'
	import debounce from 'lodash/debounce'

	export default {
		name: 'OvMainHeader',
		components: {
			OvMainMenu,
		},
		props: {},
		data() {
			return {
				debug               : false,
				// dieser wert bestimmt ob die class "--noAnimation" gesetzt wird, die alle transitions disabled
				// das ist hilfreich um die start and end dimensions der nodes zu erhalten ohne von einer
				// transition behindert zu werden
				disallowTransitions : false,
				// in diesem object werden die dims von alle elementen
				// gespeichert, die später animiert werden sollen
				dimensions          : undefined,
				// animaten states für die elmClasses
				isShrinked          : true,
				isShrinking         : false,
				isExpanded          : false,
				isExpanding         : false,
				isAnimationFinished : true,
				// main trigger
				isEnabled           : false,
				// es wird gewartet bis die ACFs geladen sind,
				// bevor das Menü auf Eingaben reagiert.
				isLoaded            : false,
				// shrink/expand werden nicht ausgeführt
				isDisabled          : false,
				// sets pointer-events:none which disables the elm from the gui
				isNotPointable      : true
			}
		},
		watch: {
			'app.acfOptions': {
				handler: async function( to, from, doLog = false ){
					if( doLog ){
						console.groupCollapsed( this.$options.name, '• watch: app.acfOptions', to)
						console.groupEnd()
					}

					if( to ){
						this.dimensions = await this.getStartAndEndDimensions()
						this.isLoaded = true
						this.isNotPointable = false
						//this.isDisabled = false
					}
				},
				immediate : true,
				deep: false,
			},
			'$route.params.lang': {
				handler: async function( to, from, doLog = false ){
					if( doLog ){
						console.groupCollapsed( this.$options.name, '• watch: $route.params.lang', to)
						console.groupEnd()
					}

					this.dimensions = await this.getStartAndEndDimensions()
				},
				immediate : false,
				deep: false,
			},
			isEnabled: {
				handler: async function( to, from, doLog = false ){
					if( doLog ){
						console.group( this.$options.name, '• watch: isEnabled', to)
						console.groupEnd()
					}

					if( !this.isDisabled ){
						if( to ){
							this.doExpandLeft2()
							this.doExpandRight2()
						}
						else{
							//this.doShrinkLeft2()
							//this.doShrinkRight2()

							if( !this.isShrinked && !this.isShrinking ){
								await this.doShrinkLeft2()
								await this.doShrinkRight2()
							}
						}
					}
				},
				immediate : false,
				deep: false,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//if( this.isDisabled ) classes.push( this.$options.name + '--isDisabled')
				if( this.isNotPointable ) classes.push( this.$options.name + '--isNotPointable')
				if( this.isLoaded ) classes.push( this.$options.name + '--isLoaded')
				if( this.isEnabled ) classes.push( this.$options.name + '--isEnabled')
				if( this.disallowTransitions ) classes.push( this.$options.name + '--disallowTransitions')

				if( this.isShrinked ) classes.push( this.$options.name + '--isShrinked')
				if( this.isShrinking ) classes.push( this.$options.name + '--isShrinking')
				if( this.isExpanded ) classes.push( this.$options.name + '--isExpanded')
				if( this.isExpanding ) classes.push( this.$options.name + '--isExpanding')

				if( this.isAnimationFinished ) classes.push( this.$options.name + '--isAnimationFinished')
				//classes.push( this.$options.name + '--state-' + this.state )

				return classes
			},
			infoText(){
				const currentLang = this.$i18n.locale
				const infoText    = this._.get( this.app, 'acfOptions.headerText__' + currentLang, '')

				return infoText
			},
		},
		methods: {
			onMouseEnter(){
				this.isEnabled = true
			},
			onMouseLeave(){
				this.isEnabled = false
				this.isDisabled = false
			},

			async flushQueue(){
				return new Promise((resolve) => setTimeout(resolve, 0));
			},
			async getElmDimensions( elm ){
				if( elm ){
					const elmStylesBefore = elm.getAttribute('style')

					//elm.setAttribute('style', '')

					const rect = elm ? elm.getBoundingClientRect() : undefined
					const height = rect ? parseFloat( rect.height.toFixed(2) ) : undefined
					const width = rect ? parseFloat( rect.width.toFixed(2)  ) : undefined

					const dimensions = {
						//height : elm ? elm.offsetHeight : undefined,
						//width  : elm ? elm.offsetWidth  : undefined,
						height : height,
						width  : width,
					}

					//elm.setAttribute('style', elmStylesBefore)

					return dimensions
				}
			},
			async getStartAndEndDimensions( doLog = false ){
				this.disallowTransitions = true
				await this.flushQueue()

				const innerElm = this.$refs.innerElm
				const logoColElm = this.$el ? this.$el.querySelector('.OvMainHeader__left') : undefined
				const menuColElm = this.$el ? this.$el.querySelector('.OvMainHeader__right') : undefined
				const leftColHiddenChilds = this.$el ? this.$el.querySelectorAll('.OvMainHeader__hiddenChild') : []
				const groupElms = this.$el ? this.$el.querySelectorAll('.OvMainMenu__group') : []
				const groupItemsElms = this.$el ? this.$el.querySelectorAll('.OvMainMenu__groupItems') : []

				// force shrinked state to get the start dims from
				logoColElm.style.height = ''
				leftColHiddenChilds.forEach( (elm, i)=>{
					elm.style.display = 'none'
				})
				groupElms.forEach( (elm, i)=>{
					elm.style.height = ''
					elm.style.width = ''
				})
				groupItemsElms.forEach( (elm, i)=>{
					elm.style.display = 'none'
				})

				// get dims before
				const innerElmDimsBefore = await this.getElmDimensions( innerElm )
				const logoColDimsBefore = await this.getElmDimensions( logoColElm )
				const menuColDimsBefore = await this.getElmDimensions( menuColElm )
				const menuGroupElms = []
				groupElms.forEach( async (elm, i)=>{
					const dims = await this.getElmDimensions( elm )
					menuGroupElms.push({ start : dims, end : undefined })
				})

				// force elms visibilty
				leftColHiddenChilds.forEach(elm=>{
					elm.style.display = 'block'
				})
				groupItemsElms.forEach(elm=>{
					elm.style.display = 'block'
				})

				// get dims after
				const innerElmDimsAfter = await this.getElmDimensions( innerElm )
				const logoColDimsAfter = await this.getElmDimensions( logoColElm )
				const menuColDimsAfter = await this.getElmDimensions( menuColElm )
				groupElms.forEach( async (elm, i)=>{
					const dims = await this.getElmDimensions( elm )
					menuGroupElms[i].end = dims
				})

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • getStartAndEndDimensions()')
					console.log('innerElm:', innerElm)
					console.log('logoColElm:', logoColElm)
					console.log('groupItemsElms:', groupItemsElms)
					console.log('---')
					console.log('innerElmDimsBefore:', innerElmDimsBefore)
					console.log('innerElmDimsAfter:', innerElmDimsAfter)
					console.log('logoColDimsBefore:', logoColDimsBefore)
					console.log('logoColDimsAfter:', logoColDimsAfter)
					console.log('menuColDimsBefore:', menuColDimsBefore)
					console.log('menuColDimsAfter:', menuColDimsAfter)
					console.groupEnd()
				}

				// reset elms visibilty
				leftColHiddenChilds.forEach(elm=>{ elm.style.display = '' })
				groupItemsElms.forEach(elm=>{ elm.style.display = '' })

				//this.state = 'allShrinked'
				this.disallowTransitions = false
				await this.flushQueue()

				return {
					'logoColElm' : {
						start : logoColDimsBefore,
						end : logoColDimsAfter,
					},
					'menuGroupElms' : menuGroupElms,
				}
			},

			async doExpandLeft2( e, doLog = false ){
				const logoColElm = this.$el.querySelector('.OvMainHeader__left')

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • doExpandLeft2()')
					console.log('logoColElm:', logoColElm)
					console.groupEnd()
				}

				logoColElm.style.height = this.dimensions.logoColElm.start.height + 'px'

				//await this.flushQueue()

				window.requestAnimationFrame(()=>{

					logoColElm.style.height = this.dimensions.logoColElm.end.height + 'px'

					this.isShrinking = false
					this.isShrinked = false
					this.isExpanded = false
					this.isExpanding = true
					this.isAnimationFinished = false

				})
			},
			async doShrinkLeft2( e, doLog = false ){
				const logoColElm = this.$el.querySelector('.OvMainHeader__left')

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • doShrinkLeft2()')
					console.log('logoColElm:', logoColElm)
					console.groupEnd()
				}

				logoColElm.style.height = this.dimensions.logoColElm.end.height + 'px'

				await this.flushQueue()

				logoColElm.style.height = this.dimensions.logoColElm.start.height + 'px'

				this.isShrinking = true
				this.isShrinked = false
				this.isExpanded = false
				this.isExpanding = false
				this.isAnimationFinished = false
			},
			async doExpandRight2( e, doLog = false ){
				const groupElms = this.$el ? this.$el.querySelectorAll('.OvMainMenu__group') : []

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • doExpandRight2()')
					console.log('groupElms:', groupElms)
					console.groupEnd()
				}

				groupElms.forEach( (elm, i)=>{
					elm.style.width = this.dimensions.menuGroupElms[i].start.width + 'px'
					elm.style.height = this.dimensions.menuGroupElms[i].start.height + 'px'
				})

				//await this.flushQueue()

				window.requestAnimationFrame(()=>{
					groupElms.forEach( (elm, i)=>{
						elm.style.width = this.dimensions.menuGroupElms[i].end.width + 'px'
						elm.style.height = this.dimensions.menuGroupElms[i].end.height + 'px'
					})
				})
			},
			async doShrinkRight2( e, doLog = false ){
				const groupElms = this.$el ? this.$el.querySelectorAll('.OvMainMenu__group') : []

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • doShrinkLeft2()')
					console.log('groupElms:', groupElms)
					console.groupEnd()
				}

				// set dims if not done before (e.g. after lang switch)
				groupElms.forEach( (elm, i)=>{
					elm.style.width = elm.getBoundingClientRect().width + 'px'
					elm.style.height = elm.getBoundingClientRect().height + 'px'
				})

				await this.flushQueue()

				groupElms.forEach( (elm, i)=>{
					elm.style.width = this.dimensions.menuGroupElms[i].end.width + 'px'
					elm.style.height = this.dimensions.menuGroupElms[i].end.height + 'px'
				})

				await this.flushQueue()

				groupElms.forEach( (elm, i)=>{
					elm.style.width = this.dimensions.menuGroupElms[i].start.width + 'px'
					elm.style.height = this.dimensions.menuGroupElms[i].start.height + 'px'
				})
			},

			// hier werden die classen für isShrinked und isExpanded gesetzt
			async onTransitionEnd( e, doLog = false ){
				if( e.propertyName == 'height' ){
					const logoColElm = e.target
					const groupElms = this.$el.querySelectorAll('.OvMainMenu__group')

					const isShrinked = parseFloat( logoColElm.style.height ) < 110
					const isExpanded = !isShrinked //parseFloat( logoColElm.style.height ) === this.dimensions.logoColElm.end.height
					const isAnimationFinished = isShrinked || isExpanded ? true : false
					const icon = isShrinked ? '↑' : isExpanded ? '↓' : ''

					// groupCollapsed group
					if( doLog ){
						console.groupCollapsed( this.$options.name + ' • onTransitionEnd()', icon)
						console.log('e:', e)
						console.log('logoColElm:', logoColElm)
						console.log('logoColElm.style.height:', logoColElm.style.height)
						console.log('isExpanded:', isExpanded)
						console.log('isShrinked:', isShrinked)
						console.log('isAnimationFinished:', isExpanded)
						console.groupEnd()
					}

					await this.flushQueue()

					this.isShrinking = false
					this.isExpanding = false
					this.isShrinked = isShrinked
					this.isExpanded = isExpanded


					// wenn wieder im shrinked ausgangszustand:
					// entfernen der styles
					if( isShrinked ){
						//logoColElm.style.height = ''

						groupElms.forEach((elm)=>{
							elm.style.height = ''
							elm.style.width = ''
						})
					}

					// warte den nächsten frame ab,
					// um die finished class zu setzen
					window.requestAnimationFrame(()=>{
						this.isAnimationFinished = isAnimationFinished
					})
				}
			},

			// force menu close after link clicked
			async onClick( e, doLog = false ){
				const targetElm = e.target
				const isLogo = targetElm.classList.contains('OvMainHeader__logoRow')
				const isGroupTitle = targetElm.classList.contains('OvMainMenu__groupTitleText')
				const isSearchBtn = targetElm.classList.contains('OvMainMenu__groupTitle--searchBtn')
				const isGroupItem = targetElm.classList.contains('OvMainMenu__groupItemText')

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name + ' • onClick()')
					console.log('e', e)
					console.log('targetElm', targetElm)
					console.log('isGroupTitle', isGroupTitle)
					console.log('isGroupItem', isGroupItem)
				}

				if( isLogo ){
					this.isDisabled = true
					this.isEnabled = false

					if( !this.isShrinked && !this.isShrinking ){
						await this.doShrinkLeft2()
						await this.doShrinkRight2()
					}
				}
				if( isGroupTitle ){
					if( !this.isExpanded && !this.isExpanding ){
						this.doExpandLeft2()
						this.doExpandRight2()
					}
				}
				if( isGroupItem || isSearchBtn ){
					this.isDisabled = true
					this.isEnabled = false

					if( !this.isShrinked && !this.isShrinking ){
						await this.doShrinkLeft2()
						await this.doShrinkRight2()
					}

					//console.log('-------')
				}



				if( doLog ){
					console.groupEnd()
				}
			},

			// after window resize we must get the dims again
			onWindowResizeHandler : debounce( function(e){
				//console.log('onWindowResize', e, this)
				this.getStartAndEndDimensions()
		    }, 100 ),

			// debug
			async doCheckDims( doLog = true ){
				const groupElms =this.$el.querySelectorAll('.OvMainMenu__group')

				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name + ' • checkDims()')
					//console.log('groupElms:', groupElms)
				}

				for( const [i, groupElm] of groupElms.entries() ){
					const elmDims = await this.getElmDimensions( groupElm )
					const savedElmDims = this.dimensions.menuGroupElms[i].start
					const isSame = elmDims.width === savedElmDims.width ? true : false
					const icon = isSame ? '✅' : '❌'
					console.log( i, groupElm, elmDims.width, icon, savedElmDims.width )
				}

				if( doLog ){
					console.groupEnd()
				}
			},
		},
		created(){
			window.addEventListener('resize', this.onWindowResizeHandler);
		},
		mounted(){
			// manchmal werden die fonts etwas später geladen,
			// deswegen werden hier die dims nochmal gelesen und gespeichert
			// nachdem alle fonts geladen wurden.
			//
			// taken from https://portalzine.de/dev/options-to-detect-when-a-font-face-has-been-loaded/
			if( document.fonts ){
				document.fonts.ready.then( async ()=>{
	  				this.dimensions = await this.getStartAndEndDimensions()
					//alert('All fonts in use by visible text have loaded.');
	   				//alert('Roboto loaded? ' + document.fonts.check('1em Roboto'));  // true
				})
			}
		},
		destroyed(){
			window.removeEventListener('resize', this.onWindowResizeHandler);
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	@OvMainHeader : {
		expandingTransitionDuration : 350ms;
		fadeInTransitionDuration : 100ms;
	}

	.OvMainHeader {	// debug wird via $data.debug angezeigt
		[showBorders4] &__inner > * {
			outline: 1px solid red;
		}
		[showBorders4] &__inner > * * {
			outline: 1px solid orange;
		}
		[showBorders4] &__right {
			background-color: fade( cyan, 30 );
		}
		[showBorders4] &__left {
			background-color: fade( green, 30 );
		}
	}
	.OvMainHeader__debug {
		position: fixed;
		left: 1em; bottom: 1em;
		display: flex;
		flex-direction: column;
		max-height: 70vh;

		background-color: pink;
		font-size: 14px;
		line-height: 16px;

		&Header {
			padding: 0.5em 0.5em 1em;
			flex-shrink: 0;
		}
		&Body {
			padding: 0.5em 0.5em 1em;
			overflow: auto;
			flex-grow: 1;
		}
	}
	.OvMainHeader {	// layout
		position: fixed !important; // just to overwrite debug outlining
		top: 0; left: 0;
		width: calc( 100vw - var(--scrollbarWidth));
		z-index: 20;
		overflow: hidden;

		&__inner {
			//position: relative;
			display: flex;
			justify-content: space-between;
			//flex-wrap: wrap;
		}
		&__left {
			//position: absolute;
			//top: 0; left: 0;
			width: 10em;
			flex-shrink: 1;
			flex-grow: 0;
			z-index: 1;

			@media @mq[sm] {
				width: 2em;
			}
		}
		&__right {
			//position: absolute;
			//top: 0; right: 0;
			overflow: hidden;
			flex-shrink: 0;
			z-index: 20;
		}

		&__logo {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
		}
		&__metaInfos { }
		&__mainMenu {}
	}
	.OvMainHeader {	// styling
		z-index: 10;
		border-bottom: 1px solid var(--color-primary-backgroundLine);
		pointer-events: none;

		&__inner > * {
			padding-top: 0.75em;
			padding-bottom: 0.85em;
		}

		&__logo {
			color: inherit;
			white-space: pre;

			&Row:nth-child(1) {
				transform: scale(1,1.2);
				padding-bottom: 1%; // fix not equal vertical gaps
			}
		}
		&__metaInfos {
			padding-top: 1.2em;

			&Text {}

			&SocialLinks {
				padding-top: 1.5em;
			}
			&SocialLink {
				display: inline-block;
				height: 1.85em; width: 3.5em;
				overflow: hidden;
				color: transparent;
				user-select: none;

				&:first-child { margin-left: -0.75em; }

				transition: all 100ms ease-out;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				opacity: 0.5; &:hover { opacity: 1; }

				&--in { background-image: url(../assets/imgs/icon--instagram.svg); }
				&--tw { background-image: url(../assets/imgs/icon--twitter.svg); }
				&--yt { background-image: url(../assets/imgs/icon--youtube.svg); }
				&--fb { background-image: url(../assets/imgs/icon--facebook.svg); }
			}
		}
		&__mainMenu {
			.OvMainMenu__groupTitle {
				//padding-bottom: 2%; // keep horizontal line with logo
			}
			.OvMainMenu__groupItems {
				//padding-top: 2%; // keep horizontal line with logo
			}
		}

		&--isLoaded {
			pointer-events: auto;
		}
		&--isShrinking {
			//pointer-events: none;
		}
		&--isNotPointable {
			pointer-events: none;
		}
	}

	.OvMainHeader { // animation styling
		&--disallowTransitions {
			&, * { transition: none !important; }
		}

		// start zustand: diese elemente sind versteckt
		// und werden erst durch die animation sichtbar
		.OvMainHeader__hiddenChild,
		.OvMainMenu__groupItems {
			display: none;
		}

		.OvMainHeader__left {
			transition: all @OvMainHeader[expandingTransitionDuration] ease-out;
			//overflow: hidden;
		}
		.OvMainHeader__hiddenChild {
			transition: all @OvMainHeader[fadeInTransitionDuration] ease-out;
		}
		.OvMainMenu__group {
			transition: all @OvMainHeader[expandingTransitionDuration] ease-out;
			overflow: hidden;
		}
		.OvMainMenu__groupItems {
			transition: all @OvMainHeader[fadeInTransitionDuration] ease-out;
			//overflow: hidden;
		}

		// expanded zustand
		&--isShrinking .OvMainHeader__hiddenChild,
		&--isExpanded .OvMainHeader__hiddenChild,
		&--isShrinking .OvMainMenu__groupItems,
		&--isExpanded .OvMainMenu__groupItems {
			display: block;
			opacity: 0;
		}
		&--isExpanded&--isAnimationFinished .OvMainHeader__hiddenChild {
			opacity: 1;
		}
		&--isExpanded&--isAnimationFinished .OvMainMenu__groupItems {
			//display: block;
			opacity: 1;
		}
	}
	XXX.OvMainHeader {    // animation styling
		transition: height @OvMainHeader[expandingTransitionDuration] ease-out;

		&--isAnimating > * {
			//pointer-events: none;
		}

		&__hiddenChild {
			display: none;
		}

		&__left {
			transition: all @OvMainHeader[expandingTransitionDuration] ease-out;
			overflow: hidden;
		}
		&__left &__hiddenChild {
			transition: all @OvMainHeader[expandingTransitionDuration] ease-out;
		}

		.OvMainMenu__group {
			transition: all @OvMainHeader[expandingTransitionDuration] ease-out;
		}
		.OvMainMenu__groupItems {
			transition: opacity @OvMainHeader[expandingTransitionDuration] ease-out;
			overflow: hidden;
			display: none;
			opacity: 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
